import _ from 'underscore';
import Backbone from 'backbone';

import EventBus from '../utils/eventbus';
import Utils from '../utils/utils';

export default Backbone.Model.extend({

	idAttribute: 'Isin',

	defaults: {
		Isin: null,
		OffExchange: false,
		Price: null,
		Date: null,
		Timestamp: null,
		Volume: null
	},

	urlRoot: '/underlying/',

	hub: 'trade',

	initialize: function() {
		this.listenTo(EventBus, 'socket:publish', this.onSocketPublish);

		if (!this.collection) {
			this.subscribe();
		}
	},

	// Subscribe to the websockets hub
	subscribe: function(ids) {
		ids = ids || [this.id];
		EventBus.trigger('socket:subscribe', this.hub, ids);
	},

	// Unsubscribe from the websockets hub
	unsubscribe: function(ids) {
		ids = ids || [this.id];
		EventBus.trigger('socket:unsubscribe', this.hub, ids);
	},

	// Private
	// Publish event from socket manager
	onSocketPublish: function(hub, data) {
		// Only get data from this hub
		if (this.hub === hub) {
			data = _.isString(data) ? JSON.parse(data) : data;

			// Only get data for this instrument
			if (data[this.idAttribute] === this.id) {
				this.setTickData(data);
			}
		}
	},

	// Set the received tick data
	setTickData: function(data) {
		this.set({
			OffExchange: data.OffExchange,
			Price: Utils.formatNumbers(data.Price, 3),
			Timestamp: data.Timestamp,
			Volume: data.Volume
		});
	}
});
