import _ from 'underscore';
import Backbone from 'backbone';

import ListModel from '../../models/list';
import InstrumentListView from '../shared/list';
import FollowNotificationView from '../shared/follow-notification';

export default Backbone.View.extend({
	initialize: function() {
		this.$search = $('#q');
		this.$loader = $('.js-loader');
		this.views = {};

		// Init model with data
		this.model = new ListModel(window.ModelData || {});

		// If results create sub views
		if (this.model.get('PagedInstruments') !== null) {
			this.views.list = new InstrumentListView({ el: '.js-instruments', model: this.model, collection: this.model });
			this.views.followNotification = new FollowNotificationView({ el: '.js-follow-notification' });
		}

		// Listen to the collection
		this.listenTo(this.model, 'request', this.showLoader);
		this.listenTo(this.model, 'sync', this.hideLoader);

		// Sets focus on search input
		this.setFocus();
	},

	render: function() {

		// Render subviews
		_.each(this.views, function(view, key) {
			view.render();
		});

		return this;
	},

	showLoader: function() {
		this.$loader.addClass('is-loading');
	},

	hideLoader: function() {
		this.$loader.removeClass('is-loading');
	},

	// Set focus to the search input.
	setFocus: function() {
		var $initialVal = this.$search.val();

		// Focus on the field, clear it and reset the original value in order to place caret at the end
		// TODO: Cleaner solution?
		this.$search.focus();
		this.$search.val('');
		this.$search.val($initialVal);
	}
});
