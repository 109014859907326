import _ from 'underscore';
import Backbone from 'backbone';

import ArchiveDatePickerView from './archive-picker';

export default Backbone.View.extend({
	initialize: function() {
		// Sub views
		this.views = {
			datePicker: new ArchiveDatePickerView({ el: '#news-archive-datepicker' })
		};
	},

	render: function() {

		// Render subviews
		_.each(this.views, function(view, key) {
			view.render();
		});

		return this;
	}
});
