import Backbone from 'backbone';
import moment from 'moment';

import Globalization from '../../utils/globalization';

export default Backbone.View.extend({

	// Pickadate: http://amsul.ca/pickadate.js/api.htm
	initialize: function() {
		// Get corresponding datepick language
		Globalization.getDatePickerTranslation();

		//Init Picker
		this.$fromPicker = this.$el.find('#from').pickadate({
			max: true
		});

		// Get Picker Object
		this.fromPicker = this.$fromPicker.pickadate('picker');

		// Add close button
		if(!this.fromPicker.$root.find('.picker__button--close')) {
			this.fromPicker.$root.find('.picker__footer').append('<button class="picker__button--close">Close</button>');
		}

		// Add close events
		this.fromPicker.$root.find('.picker__button--close').on('click', $.proxy(this.onCustomClosePicker, this));

		// Add set events
		this.fromPicker.on({
			set: $.proxy(this.onClosePicker, this)
		});
	},

	render: function() {
		return this;
	},

	onClosePicker: function(type) {
		var date = this.fromPicker.get(),
			fromDate,
			toDate,
			fromDateFormatted,
			toDateFormatted,
			url;

		if (type.select !== null && type.select !== undefined) {
			fromDate = moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY');
			toDate = moment(date, 'DD-MM-YYYY').add('days', 7).format('DD-MM-YYYY');
			fromDateFormatted = moment(date, 'DD-MM-YYYY').format('DD.MM.YYYY');
			toDateFormatted = moment(date, 'DD-MM-YYYY').add('days', 7).format('DD.MM.YYYY');
			url = window.location + '/tradehistory/' + fromDate + '/' + toDate;

			// Enable to input
			this.$el.find('#from').val(fromDateFormatted);
			this.$el.find('#to').val(toDateFormatted);

			// Show download button after selecting a date
			this.$el.find('a').addClass('is-active').attr('href', url);
		}
	},

	onCustomClosePicker: function() {
		this.fromPicker.close();
	}

});
