
export default {
	// Defaults
	lang: 'en',

	setLanguage: function(lang) {
		this.lang = lang;
	},

	// TODO: Translation from JSON oid
	getInstrumentTranslation: function() {
		var name = '';

		switch (this.lang) {
		case 'fr':
			name = 'instruments';
			break;
		case 'it':
			name = 'titoli';
			break;
		case 'de':
			name = 'instrumente';
			break;
		default:
			name = 'instruments';
			break;
		}

		return name;
	},

	// TODO: Translation from JSON oid
	getNewsArchiveTranslation: function() {
		var name = '';

		switch (this.lang) {
		case 'fr':
			name = 'news/archive';
			break;
		case 'it':
			name = 'news/archivio';
			break;
		case 'de':
			name = 'news/archiv';
			break;
		default:
			name = 'news/archive';
			break;
		}

		return name;
	},

	getDatePickerTranslation: function() {

		if (this.lang === 'fr') {
			return $.extend($.fn.pickadate.defaults, {
				monthsFull: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
				monthsShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
				weekdaysFull: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
				weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
				today: 'Today', //'Aujourd\'hui',
				clear: 'Clear', //'Effacer',
				firstDay: 0,
				format: 'dd/mm/yyyy',
				formatSubmit: 'dd/mm/yyyy'
			});

		} else if (this.lang === 'it') {
			return $.extend($.fn.pickadate.defaults, {
				monthsFull: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
				monthsShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
				weekdaysFull: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
				weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
				today: 'Today', //'Oggi',
				clear: 'Clear', //'Cancellare',
				firstDay: 0,
				format: 'dd/mm/yyyy',
				formatSubmit: 'dd/mm/yyyy'
			});

		} else if (this.lang === 'de') {
			return $.extend($.fn.pickadate.defaults, {
				monthsFull: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
				monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
				weekdaysFull: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
				weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
				today: 'Today', //'Heute',
				clear: 'Clear', //'Löschen',
				firstDay: 0,
				format: 'dd/mm/yyyy',
				formatSubmit: 'dd/mm/yyyy'
			});

		} else {
			return $.extend($.fn.pickadate.defaults, {
				firstDay: 0,
				format: 'dd/mm/yyyy',
				formatSubmit: 'dd/mm/yyyy'
			});
		}
	}
};
