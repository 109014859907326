import Backbone from 'backbone';

export default Backbone.Model.extend({
	defaults: {
		price: null,
		securityId: 'CM',
		tradeDate: null,
		tradeTime: null,
		volume: null
	},

	url: function() {
		return '/trades/' + this.get('securityId') + '/latest';
	}
});
