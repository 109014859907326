import Backbone from 'backbone';
import ToggleMixin from '../../mixins/toggle';
import EventBus from '../../utils/eventbus';

export default Backbone.View.extend({
	mixins: [ToggleMixin],

	initialize: function() {
		EventBus.on('tabOpen', this.onTabOpen, this);
	},

	onTabOpen: function(tab) {
		var triggers = Array.prototype.slice.call(this.el.querySelectorAll('.js-tab-link')),
			trigger = triggers.filter(function(trigger) {
				return trigger.getAttribute('data-tab') === tab;
			}).pop();

		triggers.forEach(function(trigger) {
			trigger.classList.remove('is-active');
		});

		if (trigger) {
			trigger.classList.toggle('is-active');
		}
	}
});
