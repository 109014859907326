import Backbone from 'backbone';

import Globalization from '../../utils/globalization';

export default Backbone.View.extend({
	// Pickadate: http://amsul.ca/pickadate.js/api.htm
	initialize: function(e) {

		// Get corresponding datepick language
		Globalization.getDatePickerTranslation();

		//Init Picker
		this.defaultVal = this.$el.val();

		this.$datePicker = this.$el.pickadate({
			format: 'dd.mm.yyyy',
			max: true
		});

		// Get Picker Object
		this.datePicker = this.$datePicker.pickadate('picker');

		// Add close button
		this.datePicker.$root.find('.picker__footer').append('<button class="picker__button--close">Close</button>');

		// Add close event
		this.datePicker.$root.find('.picker__button--close').on('click', $.proxy(this.closeDatePicker, this));

		// Add set event
		this.datePicker.on({
			set: $.proxy(this.setPage, this)
		});
	},

	render: function() {
		return this;
	},

	// Navigate to the correct archive page
	setPage: function(type) {
		var date = this.datePicker.get(),
			formattedDate = '',
			archive = Globalization.getNewsArchiveTranslation(),
			url = '';

		// Replace . with -
		formattedDate = date.replace(/[^a-z0-9]/gi, '-');
		url = window.location.protocol + '//' + window.location.host + '/' + archive + '/1/' + formattedDate + '/' + formattedDate;

		// If datepicker is closed without picking a date do nothing
		if (type !== null && (date !== this.defaultVal && date !== '')) {
			window.location = url;
		}
	},

	// Close datepicker
	closeDatePicker: function() {
		this.datePicker.close();
	}
});
