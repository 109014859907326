import _ from 'underscore';
import Backbone from 'backbone';
import Mustache from 'mustache';

import EventBus from '../../utils/eventbus';
import Orderbook from '../../models/orderbook';
import OrderbookTpl from '../../templates/Orderbook.html';

export default Backbone.View.extend({
	initialize: function() {
		if (this.$el.length === 0) {
			return;
		}

		var securityId = this.$el.data('securityId');

		if (!securityId) {
			console.error('Orderbook needs an security ID');

			return;
		}

		Mustache.parse(OrderbookTpl);

		this.orderbook = new Orderbook({
			securityId: securityId
		});

		this.listenTo(this.orderbook, 'change', this.render);
		this.listenTo(this.orderbook, 'error', this.render);
	},

	render: function() {
		var data = this.orderbook.attributes,
			view = _.extend({}, data, {
				labels: window.orderbookLabels
			});

		EventBus.trigger('orderbook:update', data.bestPrice);

		this.$el.html(Mustache.render(OrderbookTpl, view));
	}
});
