import Backbone from 'backbone';
import Mustache from 'mustache';

import EventBus from '../../utils/eventbus';
import Utils from '../../utils/utils';
import iconTemplate from '../../templates/icon.html';

export default Backbone.View.extend({
	template: $('#instrument-tmpl').html(),

	events: {
		'click .js-follow': 'toggleFollowing'
	},

	initialize: function() {
		// Parse template
		Mustache.parse(this.template);

		// Cache elements
		this.cacheElements();

		//Prevent 'Ghosting'
		this.listenTo(this.model, 'remove', this.remove);

		// Listen to model
		this.listenTo(this.model, 'change:Bid', this.setBid);
		this.listenTo(this.model, 'change:Ask', this.setAsk);
		this.listenTo(this.model, 'change:IntradayChange', this.setIntradayChange);
		this.listenTo(this.model, 'change:IntradayIsDown change:IntradayIsUp', this.setIntradayArrow);
		this.listenTo(this.model, 'change:Status', this.setStatus);
	},

	render: function(model) {
		var html = Mustache.render(this.template, this.model.attributes, {
			'Partials/Icon': iconTemplate
		});

		// Set the template
		this.setElement(html);

		// Cache elements
		this.cacheElements();

		return this;
	},

	// Cache elements
	// https://github.com/jashkenas/backbone/issues/1862
	cacheElements: function() {
		this.$intradayChange = this.$('.js-performance');
		this.$bid = this.$('.js-bid');
		this.$ask = this.$('.js-ask');

		this.$performanceIcons = this.$('[class*=js-performance-icon-]');
		this.$iconUp = this.$('.js-performance-icon-up');
		this.$iconDown = this.$('.js-performance-icon-down');
	},

	setBid: function() {
		var quote = this.model.get('Bid'),
			previousQuote = this.model.previous('Bid');

		// Set value and animate tick of it's not the same as the old value
		if (previousQuote !== quote) {
			Utils.animateTick(this.$bid, quote);
		}
	},

	setAsk: function() {
		var quote = this.model.get('Ask'),
			previousQuote = this.model.previous('Ask');

		// Set value and animate tick of it's not the same as the old value
		if (previousQuote !== quote) {
			Utils.animateTick(this.$ask, quote);
		}
	},

	setIntradayChange: function() {
		var quote = this.model.get('IntradayChange'),
			previousQuote = this.model.previous('IntradayChange');

		// Set value and animate tick of it's not the same as the old value
		if (previousQuote !== quote) {
			Utils.animateTick(this.$intradayChange, quote);
		}
	},

	setIntradayArrow: function() {
		var up = this.model.get('IntradayIsUp'),
			down = this.model.get('IntradayIsDown');

		this.$performanceIcons.addClass('is-hidden');

		// Change arrow depending on the value
		// Caching the active el is faster then .sibling()
		if (down) {
			this.$iconDown.removeClass('is-hidden');
		} else if (up) {
			this.$iconUp.removeClass('is-hidden');
		}
	},

	setStatus: function() {
		var status = this.model.get('Status'),
			suspendedText = this.model.get('SuspendedText');

		if (status === 3) {
			// Add suspended class to the container
			this.$el.addClass('suspended');

			// Set text
			$('.status-suspended .text').text(suspendedText);
		} else {
			// Remove suspended class from the container
			this.$el.removeClass('suspended');
		}
	},

	toggleFollowing: function(e) {
		var $target = $(e.currentTarget),
			isin = $target.data('isin'),
			name = $target.data('name');

		e.preventDefault();

		// Animate tick
		Utils.animateTick($target.find('.js-icon'));

		// Toggle icon (follow/ unfollow)
		$target.find('.js-icon').toggleClass('is-hidden');

		// Add/Remove following from/to the list -> models/following.js
		EventBus.trigger('following:toggle', isin);

		// Trigger > shared/following-notification
		if ($target.find('.js-icon').first().hasClass('is-hidden')) {
			EventBus.trigger('following:change', name, 'added');
		} else {
			EventBus.trigger('following:change', name, 'removed');
		}
	}
});
