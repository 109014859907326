/* jshint maxparams: 16 */
import _ from 'underscore';
import Backbone from 'backbone';
import moment from 'moment';

import EventBus from '../../utils/eventbus';
import Utils from '../../utils/utils';
import InstrumentModel from '../../models/instrument';
import TradeModel from '../../models/trade';
import ChartView from './chart';
import Datepicker from './trades-datepicker';
import BrokerDropdown from './broker-dropdown';
import DetailGAView from './ga';
import LatestTradeView from './latest-trade';
import LatestTradeListView from './latest-trade-list';
import ModalView from './modal';
import OrderbookView from './orderbook';

export default Backbone.View.extend({
	events: {
		'click .js-follow': 'onToggleFollowing'
	},

	initialize: function(options) {
		// Get Isin
		this.Isin = $('.js-instrument').data('id');

		// Init model
		this.instrumentModel = new InstrumentModel({ Isin: this.Isin });
		this.tradeModel = new TradeModel({ Isin: this.Isin });
		this.issuerId = $('#issuerId').val();

		// Cache elements
		this.$loader = $('.js-loader');
		this.$bid = $('.js-bid');
		this.$ask = $('.js-ask');

		this.$bidVolume = $('.js-bid-volume');
		this.$askVolume = $('.js-ask-volume');

		this.$performanceIcons = $('[class*=js-performance-icon-]');
		this.$iconUp = $('.js-performance-icon-up');
		this.$iconDown = $('.js-performance-icon-down');

		this.$intradayChange = $('.js-performance');

		this.$statusContainer = $('.js-status');
		this.$status = $('[class*=js-status-]');

		this.$offExchange = $('.js-off-exchange-text');

		this.$lastUpdate = $('.js-last-update');

		this.$issuerInformation = $('#issuerInformation');
		this.$issuerInfo = $('#issuerInfoTmpl');
		this.$dividendTab = $('#dividendTab');
		this.$issuerInformationContainer = $('#issuerInformationContainer');
		this.$dividendContainer = $('#dividendContainer');

		// Sub views
		this.views = {
			chart: new ChartView({ el: '.js-chart' }),
			datepicker: new Datepicker({ el: '#datepicker' }),
			brokerDropdown: new BrokerDropdown({ el: '#broker-dropdown' }),
			googleAnalytics: new DetailGAView({ el: '.l-main-content' }),
			latestTrade: new LatestTradeView({ el: '.js-latest-trade' }),
			latestTradeList: new LatestTradeListView({ el: '.js-latest-trade-list' }),
			modalView: new ModalView({ el: document.body }),
			orderbook: new OrderbookView({ el: '.js-orderbook' })
		};

		// Listen to changes in the model
		this.listenTo(this.instrumentModel, 'change', this.setLastUpdatedTime);
		this.listenTo(this.instrumentModel, 'change:Bid', this.setBid);
		this.listenTo(this.instrumentModel, 'change:BidVolume', this.setBidVolume);
		this.listenTo(this.instrumentModel, 'change:Ask', this.setAsk);
		this.listenTo(this.instrumentModel, 'change:AskVolume', this.setAskVolume);
		this.listenTo(this.instrumentModel, 'change:AskRaw change:BidRaw', this.setChartBidAskTicks);

		this.listenTo(this.instrumentModel, 'change:IntradayChange', this.setIntraday);
		this.listenTo(this.instrumentModel, 'change:IntradayIsDown change:IntradayIsUp', this.setIntradayArrow);
		this.listenTo(this.instrumentModel, 'change:Status', this.setStatus);

		// Listen to changes in the trade model
		this.listenTo(this.tradeModel, 'change', this.setUpdateTime);
		this.listenTo(this.tradeModel, 'change:Price change:OffExchange', this.setTrade);
		this.listenTo(this.tradeModel, 'change:Volume', this.setTradeVolume);

		this.listenTo(this.tradeModel, 'change:Volume', this.setChartVolumeTicks);

		// Toggle broker dropdown -> views/instrument-detail/broker-dropdown.js
		this.listenTo(EventBus, 'brokers:toggle', this.onToggleBrokerDropdown);
	},

	render: function() {
		// Render subviews
		_.each(this.views, function(view, key) {
			view.render();
		});

		return this;
	},
	formatClause: function() {
		return function(val, render) {
			return render(val) === 'in' ?
						'In (33 1/3%)' :
						render(val) === 'out' ?
						'Out' :
						render(val) === 'up' ?
						'Up (49%)' :
						'';
		};
	},
	formatChf: function() {
		return function(val, render) {
			var nval = render(val),
				chfVal = parseFloat(Math.round(nval * 100) / 100).toFixed(2);
			if (nval > 1000)
			{
				chfVal = chfVal.substr(0, chfVal.length - 6) + '\'' + chfVal.substr(chfVal.length - 6);
			}
			if (nval > 1000000)
			{
				chfVal = chfVal.substr(0, chfVal.length - 10) + '\'' + chfVal.substr(chfVal.length - 10);
			}
			return 'CHF ' + chfVal;
		};
	},
	formatBool: function() {
		return function(val, render) {
			return render(val) === 'true' ? 'Yes' : 'No';
		};
	},
	formatDate: function() {
		return function(date, render) {
			var cleanedDate = parseInt(render(date).replace('&#x2F;Date(', '').replace(')&#x2F;', ''), 10);
			return moment(cleanedDate).format('Do MMMM YYYY');
		};
	},
	setLastUpdatedTime: function() {
		if (!this.instrumentModel.get('Timestamp')) {
			return;
		}

		var timestamp = this.instrumentModel.get('Timestamp'),
			previousTimestamp = this.instrumentModel.previous('Timestamp'),
			lastUpdate = moment.utc(timestamp).format('HH:mm:ss');

		if (previousTimestamp && previousTimestamp !== timestamp) {
			this.$lastUpdate.text(lastUpdate + ' CET');
		}
	},

	setUpdateTime: function() {
		var $lastTradedDate = this.$('.js-last-traded-date'),
			$lastTradedTime = this.$('.js-last-traded-time'),
			timestamp = this.tradeModel.get('Timestamp'),
			timestampPrevious = this.tradeModel.previous('Timestamp'),
			date = moment.utc(timestamp).format('DD.MM.YYYY'),
			datePrevious = moment.utc(timestampPrevious).format('DD.MM.YYYY'),
			time = moment.utc(timestamp).format('HH:mm:ss'),
			timePrevious = moment.utc(timestampPrevious).format('HH:mm:ss');

		if (date !== datePrevious) {
			$lastTradedDate.text(date);
		}

		if (time !== timePrevious) {
			$lastTradedTime.text(time);
		}
	},

	setChartBidAskTicks: function() {
		if (typeof this.instrumentModel.get('Timestamp') !== 'number') {
			return;
		}

		if (this.instrumentModel.get('AskRaw') == 0 || this.instrumentModel.get('BidRaw')) {
			return;
		}

		// Trigger to add the new data to the ChartModel -> views/instrument-detail/chart.js
		EventBus.trigger('chart:BidAskTick', this.instrumentModel.get('AskRaw'), this.instrumentModel.get('BidRaw'), this.instrumentModel.get('Timestamp'));
	},

	setChartVolumeTicks: function() {
		if (typeof this.instrumentModel.get('Timestamp') !== 'number') {
			return;
		}

		// Trigger to add the new data to the ChartModel -> views/instrument-detail/chart.js
		EventBus.trigger('chart:VolumeTick', this.tradeModel.get('Volume'), this.tradeModel.get('Timestamp'));
	},

	setTrade: function() {
		var $lastTradedPrice = this.$('.js-last-traded-price'),
			offExchange = this.tradeModel.get('OffExchange'),
			price = this.tradeModel.get('Price'),
			previousPrice = this.tradeModel.previous('Price');

		if (offExchange) {
			// Show off exchange div and hide price (BXSUP-361)
			this.$offExchange.removeClass('is-hidden');

			Utils.animateTick($lastTradedPrice, '&ndash;');
		} else {
			// Hide off exchange div and show price
			this.$offExchange.addClass('is-hidden');

			if (previousPrice !== price) {
				Utils.animateTick($lastTradedPrice, price);
			}
		}
	},

	setTradeVolume: function() {
		var $lastTradedVolume = this.$('.js-last-traded-volume'),
			volume = this.tradeModel.get('Volume'),
			previousVolume = this.tradeModel.previous('Volume');

		// Set value and animate tick of it's not the same as the old value
		if (previousVolume !== volume) {
			Utils.animateTick($lastTradedVolume, volume);
		}
	},

	setBid: function() {
		var quote = this.instrumentModel.get('Bid'),
			previousQuote = this.instrumentModel.previous('Bid');

		// Set value and animate tick of it's not the same as the old value
		if (previousQuote && previousQuote !== quote) {
			Utils.animateTick(this.$bid, quote);
		}
	},

	setBidVolume: function() {
		var volume = this.instrumentModel.get('BidVolume'),
			previousVolume = this.instrumentModel.previous('BidVolume');

		// Set value and animate tick of it's not the same as the old value
		if (previousVolume && previousVolume !== volume) {
			Utils.animateTick(this.$bidVolume, volume);
		}
	},

	setAsk: function() {
		var quote = this.instrumentModel.get('Ask'),
			previousQuote = this.instrumentModel.previous('Ask');

		// Set value and animate tick of it's not the same as the old value
		if (previousQuote && previousQuote !== quote) {
			Utils.animateTick(this.$ask, quote);
		}
	},

	setAskVolume: function() {
		var volume = this.instrumentModel.get('AskVolume'),
			previousVolume = this.instrumentModel.previous('AskVolume');

		// Set value and animate tick of it's not the same as the old value
		if (previousVolume && previousVolume !== volume) {
			Utils.animateTick(this.$askVolume, volume);
		}
	},

	setIntraday: function() {
		var quote = this.instrumentModel.get('IntradayChange'),
			previousQuote = this.instrumentModel.previous('IntradayChange');

		// Set value and animate tick of it's not the same as the old value
		if (previousQuote !== quote) {
			Utils.animateTick(this.$intradayChange, quote);
		}
	},

	setIntradayArrow: function() {
		var up = this.instrumentModel.get('IntradayIsUp'),
			down = this.instrumentModel.get('IntradayIsDown');

		this.$performanceIcons.addClass('is-hidden');

		// Change arrow depending on the value
		// Caching the active el is faster then .sibling()
		if (down) {
			this.$iconDown.removeClass('is-hidden');
		} else if (up) {
			this.$iconUp.removeClass('is-hidden');
		}
	},

	setStatus: function() {
		var status = this.instrumentModel.get('Status');

		// 1. Market closed
		// 2. Tradeable
		// 3. Instrument Suspended
		// 4. Pre-open

		this.$status.addClass('is-hidden');

		if (status === 1) {
			this.$statusContainer.find('.js-status-closed').removeClass('is-hidden');
		} else if (status === 2) {
			this.$statusContainer.find('.js-status-tradable').removeClass('is-hidden');
		} else if (status === 3) {
			this.$statusContainer.find('.js-status-suspended').removeClass('is-hidden');
		} else if (status === 4) {
			this.$statusContainer.find('.js-status-preopen').removeClass('is-hidden');
		}
	},

	onToggleFollowing: function(e) {
		var $target = $(e.currentTarget),
			isin = $target.data('isin'),
			currentText = $target.find('.js-following-text').text(),
			toggleText = $target.data('toggle-text');

		e.preventDefault();

		// Toggle icon (follow/ unfollow)
		$target.find('.js-icon').toggleClass('is-hidden');

		// Toggle follow/unfollow text
		$target.find('.js-following-text').text(toggleText);
		$target.data('toggleText', currentText);

		// Add/Remove following from/to the list -> models/following.js
		EventBus.trigger('following:toggle', isin);
	},

	onToggleBrokerDropdown: function() {
		var $body = this.$el.parent();

		// If the overlay is already drawn
		if ($body.find('.broker-dropdown-outside').length) {
			$body.find('.broker-dropdown-outside').remove();

		} else {
			// add the overlay and add click event
			$body.append('<div class="broker-dropdown-outside"></div>');
			$body.find('.broker-dropdown-outside').on('click', function() {
				$(this).remove(); // Remove overlay
				EventBus.trigger('brokers:close'); // Trigger to close the dropdown
			});
		}
	},

	showLoader: function() {
		this.$loader.addClass('is-loading');
	},

	hideLoader: function() {
		this.$loader.removeClass('is-loading');
	}
});
