import _ from 'underscore';
import Backbone from 'backbone';
import EventBus from '../utils/eventbus';
import Utils from '../utils/utils';

export default Backbone.Model.extend({
	idAttribute: 'Isin',

	defaults: {
		Isin: null,
		Symbol: '',
		Name: '',
		IntradayChange: null,
		IntradayIsDown: false,
		IntradayIsUp: false,
		Mid: null,
		Bid: null,
		BidRaw: null,
		Ask: null,
		AskRaw: null,
		NAV: null,
		TER: null,
		BidVolume: null,
		AskVolume: null,
		Timestamp: 0,
		Status: 2, // 1. Market closed - 2. Tradeable - 3. Instrument Suspended - 4. Pre-open
		Following: false,
		Suspended: false, // For Mustache Template
		SuspendedText: 'Suspended',
		Icons: {},
		Url: '',
		Nav: null,
		IsFund: false
	},

	urlRoot: '/instrument/',

	hub: 'instrument',

	initialize: function() {
		this.listenTo(EventBus, 'socket:publish', this.onSocketPublish);

		if (!this.collection) {
			this.subscribe();
		}
	},

	// Subscribe to the websockets hub
	subscribe: function(ids) {
		ids = ids || [this.id];
		EventBus.trigger('socket:subscribe', this.hub, ids);
	},

	// Unsubscribe from the websockets hub
	unsubscribe: function(ids) {
		ids = ids || [this.id];
		EventBus.trigger('socket:unsubscribe', this.hub, ids);
	},

	// Private
	// Publish event from socket manager
	onSocketPublish: function(hub, data) {
		// Only get data from this hub
		if (this.hub === hub) {
			data = _.isString(data) ? JSON.parse(data) : data;

			// Only get data for this instrument
			if (data[this.idAttribute] === this.id) {
				this.setTickData(data);
			}
		}
	},

	// Set the received tick data
	setTickData: function(data) {
		this.set({
			Mid: data.Mid,
			Ask: data.Ask,
			Bid: data.Bid,
			AskFormatted: data.AskFormatted,
			BidFormatted: data.BidFormatted,
			BidVolume: data.BidVolume,
			AskVolume: data.AskVolume,
			IntradayChange: data.IntradayChange ? Utils.formatNumbers(data.IntradayChange, 2, '%') : null,
			IntradayIsDown: data.IntradayIsDown,
			IntradayIsUp: data.IntradayIsUp,
			Timestamp: data.Timestamp,
			Status: data.Status,
			Suspended: data.Suspended,
			SuspendedText: data.SuspendedText,
			Url: data.Url,
		});
	}
});
