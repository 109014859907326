import Backbone from 'backbone';

var Config = Backbone.Model.extend({
	defaults: {
		VERSION: '0.1',
		locale: 'en', // en, fr, de, it
		restEndPoint: '',
		pushEndPoint: '',
		hubs: {
			instrument: '',
			orderbook: '',
			trade: ''
		}
	}
});

const scriptElement = document.currentScript ? document.currentScript : document.querySelector('script[data-config]');

export default new Config(JSON.parse(scriptElement.dataset.config));
