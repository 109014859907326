import _ from 'underscore';

import Backbone from 'backbone';

import Mustache from 'mustache';

import LatestTrade from '../../models/latest-trade';
import LatestTradeTlp from '../../templates/LatestTrade.html';

export default Backbone.View.extend({
	initialize: function () {
        if (this.$el.length === 0) {
			return;
		}

		var securityId = this.$el.data('securityId');

		if (!securityId) {
			console.error('LatestTrade needs a security ID');
			return;
		}

		Mustache.parse(LatestTradeTlp);

		this.latestTrade = new LatestTrade({
			securityId: securityId
		});

		this.listenTo(this.latestTrade, 'change', this.onData);

		// The website isn't quite ready for this many requests - DISABLED
		// setInterval(_.bind(this.update, this), 2000);
		this.update();
	},

	update: function() {
		this.latestTrade.fetch();
	},

	onData: function() {
		var data = this.latestTrade.attributes,
			view = _.extend({}, data, {
				labels: window.latestTradeLabels
			});

		this.$el.html(Mustache.render(LatestTradeTlp, view));
	}
});
