import _ from 'underscore';
import Backbone from 'backbone';

import EventBus from '../../utils/eventbus';
import ListModel from '../../models/list';
import FollowingModel from '../../models/following';
import InstrumentListView from '../shared/list';
import FollowingGAView from './following-ga';

export default Backbone.View.extend({
	initialize: function() {
		// Init model with data
		this.model = new ListModel(window.ModelData || {});
		this.followingModel = new FollowingModel();
		this.views = {};

		// If results create sub views
		if (this.$el.find('.js-instrument-list').length) {
			this.views = {
				list: new InstrumentListView({ el: '.js-instruments', model: this.model, collection: this.model }),
				googleAnalytics: new FollowingGAView({ el: '.js-instrument-list' })
			};
		}

		// Event Listeners
		this.listenTo(EventBus, 'following:toggle', this.removeFromFollowing);
		this.listenTo(this.followingModel, 'change:count', this.isNotFollingAnything);
	},

	render: function() {

		// Render subviews
		_.each(this.views, function(view, key) {
			view.render();
		});

		return this;
	},

	// Visually remove row from view
	removeFromFollowing: function(isin) {
		var $instrument = this.$el.find('[data-id="' + isin + '"]'),
			$box = $instrument.closest('.js-box');

		// Remove box from dom
		$box.remove();

		EventBus.trigger('following:remove', isin);
	},

	// Show 'You are not following any instruments' message when deleting the last instrument on your list
	isNotFollingAnything: function() {
		if (this.followingModel.get('count') === 0) {
			$('.js-no-results').hide().removeClass('is-hidden').fadeIn(400);
			$('.instrument-list').remove();
		}
	}
});
