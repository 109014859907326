import _ from 'underscore';
import Backbone from 'backbone';

import EventBus from '../../utils/eventbus';
import Utils from '../../utils/utils';

export default Backbone.View.extend({
	activeClass: 'is-active',

	events: {
		'click .broker-dropdown-toggle': 'onToggle'
	},

	initialize: function() {
		this.brokerToggle = $('.broker-dropdown-toggle');
		this.brokerList = $('.broker-dropdown-list');

		// Event Listeners
		this.listenTo(EventBus, 'brokers:close', this.onCloseDropdown);

		// Resize the width of the dropdown onResize
		var resizeBrokerList = _.debounce($.proxy(this.setBrokerListWidth, this), 300);
		$(window).resize(resizeBrokerList);
	},

	render: function() {

		// Set the width of the dropdown
		this.setBrokerListWidth();

		return this;
	},

	setBrokerListWidth: function() {
		var colWidth = this.$el.width();

		// Adjust to collumned view after medium breakpoint
		if (Utils.isMedium()) {

			colWidth = colWidth + 40;
			this.brokerList.width(colWidth * 2);

		} else {

			this.brokerList.width('auto');
		}
	},

	onToggle: function(e) {
		e.preventDefault();

		// Add corresponding classes
		this.brokerToggle.toggleClass(this.activeClass);
		this.brokerList.toggleClass(this.activeClass);

		// Trigger to add the overlay for closing the dropdown when clicked outside the toggle
		EventBus.trigger('brokers:toggle');
	},

	// Remove corresponding classes
	onCloseDropdown: function() {
		this.brokerToggle.removeClass(this.activeClass);
		this.brokerList.removeClass(this.activeClass);
	}
});
