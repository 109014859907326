import _ from 'underscore';
import Backbone from 'backbone';
import InstrumentModel from '../models/instrument';

export default Backbone.Collection.extend({
	subscription: false, // Is this collection already subscribed?

	model: InstrumentModel,

	url: function() {
		return document.URL;
	},

	initialize: function(models, options) {
		// Event listeners
		this.listenTo(this, 'reset', this.onReset);
		this.listenTo(this, 'reset', this.subscribe);
		this.listenTo(this, 'request', this.unsubscribe);
	},

	parse: function(response) {
		return response.Instruments;
	},

	// Private
	// Trigger 'remove' event on the previous models, so that connected views can be removed
	onReset: function(collection, options) {
		_.each(options.previousModels, function(model) {
			model.trigger('remove');
		});
	},

	// Subscribe to the websockets hub
	// Just call the subscribe method on te model, it already has knows how to connect to the hub (DRY).
	subscribe: function() {
		if (this.subscription) {
			return false;
		}

		var ids = this.getIds();

		this.model.prototype.subscribe.call(this.model.prototype, ids);

		this.subscription = true;
	},

	// Unsubscribe from the websockets hub
	// Just call the unsubscribe method on te model, it already has knows how to disconnect from the hub (DRY).
	unsubscribe: function() {
		if (!this.subscription) {
			return false;
		}

		var ids = this.getIds();

		this.model.prototype.unsubscribe.call(this.model.prototype, ids);

		this.subscription = false;
	},

	// Get all model IDs from the collection
	// Returns array
	getIds: function() {
		var idAttribute = this.model.prototype.idAttribute;

		return this.pluck(idAttribute);
	}
});
