import Backbone from 'backbone';

export default Backbone.View.extend({
	events: {
		'submit .js-newsletter-form': 'onSubmit'
	},

	render: function() {
		this.$form = $('.js-newsletter-form');
		this.$notification = $('.js-newsletter-notification');
		this.$loader = this.$el;

		this.notifications = {
			fieldError: this.$form.data('field-error'),
			serverError: this.$form.data('server-error'),
			complete: this.$form.data('complete'),
			duplicate: this.$form.data('duplicate')
		};

		return this;
	},

	onSubmit: function(e) {
		e.preventDefault();

		this.showLoader();

		// Ajax to MailChimp
		this.registerForm(this.$form);
	},

	registerForm: function($form) {
		var self = this;

		$.ajax({
			type: $form.attr('method'),
			url: $form.attr('action'),
			data: $form.serialize(),
			cache: false,
			dataType: 'json',
			contentType: 'application/json; charset=utf-8',

			error: function(err) {
				self.hideLoader();

				self.showServerErrorMsg();
			},

			success: function(data) {
				self.hideLoader();

				if (data.result !== 'success') {

					if (data.msg.indexOf('href') > -1) {
						self.showRegistrationDuplicateMsg();
					} else {
						self.showRegistrationErrorMsg();
					}

				} else {
					self.showRegistrationMsg();
				}
			}
		});
	},

	showLoader: function() {
		this.$loader.addClass('is-loading');
	},

	hideLoader: function() {
		this.$loader.removeClass('is-loading');
	},

	// Show/Hide error/success messages
	showRegistrationMsg: function() {
		this.$form.hide();

		this.$notification.removeClass('is-hidden').text(this.notifications.complete);
	},

	showRegistrationDuplicateMsg: function() {
		this.$form.hide();

		this.$notification.removeClass('is-hidden').text(this.notifications.duplicate);
	},

	showRegistrationErrorMsg: function() {
		this.$notification.removeClass('is-hidden').text(this.notifications.fieldError);
	},

	showServerErrorMsg: function() {
		this.$notification.removeClass('is-hidden').text(this.notifications.serverError);
	}
});
