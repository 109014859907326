import Backbone from 'backbone';
import InstrumentModel from '../../models/instrument';

var ANIMATION_PIXELS_PER_SECOND = 100;

var CLASS_PERFORMANCE_NEGATIVE = 'performance--negative';
var CLASS_PERFORMANCE_POSITIVE = 'performance--positive';

export default Backbone.View.extend({
	initialize: function() {
		this.models = Array.prototype.slice.call(this.el.querySelectorAll('[data-isin]')).map(function (item) {
			return new InstrumentModel({ Isin: item.dataset.isin })
		});

		this.isPaused = false;
		this.styleElement = this.createStyleElement(document.getElementById('script-main').getAttribute('nonce'));

		this.elClone = this.createClone();
		this.update();

		var self = this;

		this.models.forEach(function (model) {
			self.listenTo(model, 'change', self.onModelChange);
		});

		window.addEventListener('resize', this.update());
	},

	createClone: function() {
		var clone = this.el.firstElementChild.cloneNode(true);

		clone.setAttribute('aria-hidden', true);

		this.el.appendChild(clone);

		return clone;
	},

	createStyleElement: function(nonce) {
		var element = document.createElement('style');

		element.setAttribute('rel', 'stylesheet');
		element.setAttribute('nonce', nonce);

		document.head.appendChild(element);

		return element;
	},

	onModelChange: function(model) {
		Array.prototype.slice.call(this.el.querySelectorAll('[data-isin]'))
			.filter(function (item) {
				return item.dataset.isin === model.get('Isin');
			})
			.forEach(function (item) {
				var elementPerformance = item.querySelector('.js-performance');
				var elementPerformanceValue = item.querySelector('.js-performance-value');

				elementPerformance.classList.remove(CLASS_PERFORMANCE_NEGATIVE);
				elementPerformance.classList.remove(CLASS_PERFORMANCE_POSITIVE);

				if (model.get('IntradayIsDown')) {
					elementPerformance.classList.add(CLASS_PERFORMANCE_NEGATIVE);
				}

				if (model.get('IntradayIsUp')) {
					elementPerformance.classList.add(CLASS_PERFORMANCE_POSITIVE);
				}

				elementPerformanceValue.textContent = model.get('IntradayChange');
			});
	},

	update: function() {
		var width = this.el.querySelector('.marquee__body').clientWidth;

		if (width < window.innerWidth) {
			this.elClone.classList.add('is-hidden');
			this.styleElement.textContent = '';

			return;
		}

		var duration = width / ANIMATION_PIXELS_PER_SECOND;

		this.elClone.classList.remove('is-hidden');
		this.styleElement.textContent = [
			'@keyframes marquee {',
				'from { transform: translateX(0); }',
				'to { transform: translateX(-' + width + 'px); }',
			'}',

			'.marquee__body {',
				'animation: marquee ' + duration + 's linear infinite;',
			'}',

			'.marquee:hover .marquee__body {',
				'animation-play-state: paused;',
			'}',
		].join('');
	},
});
