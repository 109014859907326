import _ from 'underscore';
import Highcharts from 'highcharts/highstock';

var fontFamily = '"NHaasGroteskRg", Helvetica, Arial, sans-serif',
	labelStyle = {
		color: '#282828',
		fontSize: '12px',
		fontFamily: fontFamily
	},
	labelStyleTitle = _.extend({ fontWeight: 'bold' }, labelStyle);

// http://www.highcharts.com/docs/chart-design-and-style/design-and-style
// Chart default config
export default {
	chart: {
		spacingTop: 14,
		spacingRight: 14,
		spacingBottom: 14,
		spacingLeft: 14,
		marginRight: 55,
		borderWidth: 0,
		borderRadius: 0,
		borderColor: '#282828',
		style: labelStyle
	},

	// Line colors
	colors: [
		'#282828', //black
		'#b31a21'  //red
	],

	credits: { enabled: false },
	exporting: { enabled: false },
	legend: { enabled: false },
	navigator: { enabled: false },

	plotOptions: {
		candlestick: {
			color: '#b31a21', //red
			lineColor: '#b31a21',
			upColor: '#282828', // Black
			upLineColor: '#282828'
		},
		ohlc: {
			color: '#282828'
		},
		series: {
			dataGrouping: {
				enabled: false
			},
			marker: {
				//lineWidth: 0,
				//radius: 2,
				fillColor: '#fff',
				states: {
					hover: {
						//lineWidth: 2,
						//lineWidthPlus: 1,
						radiusPlus: 3
					}
				}
			},
			states: {
				hover: {
					lineWidthPlus: 0
				}
			}
		}
	},

	rangeSelector: { enabled: false },
	scrollbar: { enabled: false },

	series: [
		{
			type: 'line',
			name: 'Ask',
			id: 'instrument-ask',
			yAxis: 'y-axis-quotes',
			marker: {
				enabled: false,
				symbol: 'circle',
				lineColor: '#282828',
				fillColor: '#FFF',
				lineWidth: 1
			}
		},
		{
			type: 'line',
			name: 'Bid',
			id: 'instrument-bid',
			yAxis: 'y-axis-quotes',
			marker: {
				enabled: false,
				symbol: 'circle',
				lineColor: '#b31a21',
				fillColor: '#FFF',
				lineWidth: 1
			}
		},
		{
			type: 'line',
			name: 'Nav',
			id: 'instrument-nav',
			yAxis: 'y-axis-quotes',
			marker: {
				enabled: false,
				symbol: 'circle',
				lineColor: '#b31a21',
				fillColor: '#FFF',
				lineWidth: 1
			}
		},
		{
			type: 'line',
			name: 'Mid',
			id: 'instrument-mid',
			yAxis: 'y-axis-quotes',
			marker: {
				enabled: false,
				symbol: 'circle',
				lineColor: '#282828',
				fillColor: '#FFF',
				lineWidth: 1
			}
		},
		{
			type: 'ohlc',
			name: 'OHLC',
			id: 'instrument-ohlc',
			yAxis: 'y-axis-quotes'
		},
		{
			type: 'column',
			name: 'Volume',
			id: 'instrument-volume',
			yAxis: 'y-axis-volumes',
			xAxis: 'x-axis-volumes',
			color: '#bbb',
			pointWidth: 1	//prevents the volume columns from getting wider than 1 point (this happened in intraday)
		}
	],

	tooltip: {
		shared: true,
		useHTML: true,
		borderWidth: 0,
		backgroundColor: 'none',
		shadow: false,
		style: {
			fontFamily: fontFamily,
			fontSize: '16px'
		},
		formatter: function() {
			var header = '<span class="chart-tooltip"><strong class="chart-tooltip-header">' + Highcharts.dateFormat('%A %d %b %Y, %H:%M:%S', this.x) + '</strong>',
				series = '',
				footer = '</span>';

			// Loop through the series
			$.each(this.points, function(i, point) {
				var value = Highcharts.numberFormat(point.y, 3);

				// No decimals for the volume value
				if (this.series.name === 'Volume') {
					value = Highcharts.numberFormat(point.y, 0);
				}

				series += '<span class="chart-tooltip-item" style="border-top: 2px solid ' + this.series.color + '; color: ' + this.series.color + ';">' +
					'<span class="chart-tooltip-label" style="color:' + this.series.color + '">' + this.series.name + '</span>' +
					'<strong class="chart-tooltip-value">' + value + '</strong></span>';
			});

			return header + series + footer;
		}
	},

	xAxis: [
		{
			id: 'x-axis-quotes',
			type: 'datetime',
			dateTimeLabelFormats: {
				minute: '%H:%M'
			},
			minPadding: 0,
			maxPadding: 0,
			ordinal: false, // Get overwritten in this.setChartSettings(), depending on the timeframe. //Hide weekends and other gaps
			gridLineWidth: 0,
			lineColor: '#e5e5e5',
			labels: {
				style: labelStyle
			}
		},
		{
			id: 'x-axis-volumes',
			type: 'datetime',
			ordinal: false,
			lineColor: 'transparent',
			lineWidth: 0,
			minorTickLength: 0,
			tickLength: 0,
			labels: {
				enabled: false
			}
		}
	],

	yAxis: [
		{
			id: 'y-axis-quotes',
			opposite: false,
			gridLineColor: '#eeeeee',
			labels: {
				align: 'left',
				style: labelStyle,
				format: '{value:.3f}',
				x: -52
			},
			title: {
				align: 'high',
				text: 'Price',
				rotation: 0,
				style: labelStyleTitle,
				textAlign: 'left',
				x: -42
			}
		},
		{
			id: 'y-axis-volumes',
			opposite: true,
			lineWidth: 0,
			gridLineWidth: 0,
			labels: {
				enabled: false,
				align: 'left',
				style: labelStyle,
				format: '{value}',
				x: 0
			},
			title: {
				align: 'high',
				text: null,
				rotation: 0,
				style: labelStyleTitle
			}
		}
	]
};
