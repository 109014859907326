import Backbone from 'backbone';

export default Backbone.View.extend({
	events: {
		'click .to-following': 'setFollowingRemoved'
	},

	initialize: function() {
		if (!window.ga) {
			this.events = {};
			this.delegateEvents();
		}
	},

	// send, event, category, action, label, value
	setFollowingRemoved: function(e) {
		var isin = $(e.currentTarget).data('isin');

		window.ga('send', 'event', 'Following', 'Remove', 'Removed Instrument: ' + isin);
	}
});
