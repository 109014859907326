import _ from 'underscore';
import Backbone from 'backbone';
import Mustache from 'mustache';

import EventBus from '../../utils/eventbus';
import LatestTradeListModel from '../../models/latest-trade-list';
import LatestTradeListTpl from '../../templates/LatestTradeList.html';

export default Backbone.View.extend({
	initialize: function () {
        if (this.$el.length === 0) {
			return;
		}

		var securityId = this.$el.data('securityId');

		if (!securityId) {
			console.error('LatestTradeList needs a security ID');
			return;
		}

		Mustache.parse(LatestTradeListTpl);

		this.latestTradeList = new LatestTradeListModel({
			securityId: securityId
		});

		this.listenTo(this.latestTradeList, 'change', this.onData);
		this.listenTo(this.latestTradeList, 'error', this.onData);
		this.onData();

		EventBus.on('tabOpen', this.onTabOpen, this);
	},

	update: function() {
		this.latestTradeList.fetch();
	},

	onData: function() {
		var data = this.latestTradeList.attributes,
			view = _.extend({}, data, {
				labels: window.latestTradeListLabels
			});

		this.$el.html(Mustache.render(LatestTradeListTpl, view));
	},

	onTabOpen: function(tab) {
		if (!this.el.closest('#' + tab)) {
			return;
		}

		this.update();
	}
});
