import Backbone from 'backbone';
import LanguageModel from '../../models/language';

export default Backbone.View.extend({
	events: {
		'change': 'onLanguageChange'
	},

	initialize: function() {
		this.model = new LanguageModel();
	},

	render: function(e) {
		return this;
	},

	onLanguageChange: function(e) {
		this.model.set({
			languageId: e.target.value
		});

		return this.model.fetch({
			success: function(model, response) {
				if (!response) {
					return;
				}

				window.location.reload();
			}
		});
	}
});
