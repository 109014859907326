export default {
	events: {
		'click .js-toggle': 'toggle'
	},

	initialize: function() {
		// references to elements
		var toggleElementClass = this.el.getAttribute('data-toggle-element');

		this.toggleElements = Array.from(document.querySelectorAll('.' + toggleElementClass)) || [this.el];
		this.toggleClass = this.el.getAttribute('data-toggle-class') || 'is-open';
	},

	toggle: function(e) {
		var self = this;

		e.preventDefault();

		var hidden = this.toggleElements
			.map(function (toggleElement) {
				return toggleElement.classList.toggle(self.toggleClass);
			})
			.shift();

		if (!hidden) {
			var offsetTop = this.toggleElements.reduce(function (offset, element) {
				return Math.min(offset, element.offsetTop);
			}, Infinity);

			window.scrollTo(0, offsetTop);
		}
	}
};
