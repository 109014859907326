import Backbone from 'backbone';

export default Backbone.Model.extend({
	defaults: {
		trades: []
	},

	url: function() {
		return '/trades/' + this.get('securityId') + '?=_' + Date.now();
	},

	parse: function(response, options) {
		var isEmpty = response.length === 0;

		return {
			isEmpty: isEmpty,
			trades: response
		};
	}
});

