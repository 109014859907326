import Backbone from 'backbone';

export default Backbone.View.extend({
	events: {
		'click .js-show-modal': 'showModal',
		'click .js-hide-modal': 'hideModal'
	},

	initialize: function() {
		// Cache elements
		this.$modal = $('.js-modal');
		this.$modalContent = $('.js-modal-content');
	},

	showModal: function(e) {
		var $currentTarget = $(e.currentTarget);

		e.preventDefault();

		// Hide all content
		this.$modalContent.children().addClass('is-hidden');

		this.$modal
			.removeClass('is-hidden')
			.find(this.getModalTarget($currentTarget))
			.removeClass('is-hidden');
	},

	getModalTarget: function(target) {
		return '.' + target.data('modal-target');
	},

	hideModal: function(e) {
		// Don't close when clicking in content area
		if ($(e.target).closest('.js-modal-content').length) {
			return;
		}

		this.$modal.addClass('is-hidden');
	}
});
