import _ from 'underscore';
import Cookies from 'js-cookie';
import Backbone from 'backbone';
import EventBus from '../utils/eventbus';

export default Backbone.Model.extend({
	defaults: {
		id: 'following', // Cookie name
		isins: [],
		count: 0,
		name: '' // The name of the last instrument added/removed to the list
	},

	initialize: function() {

		// Get data from Cookie
		this.fetchData();

		// Event Listeners
		this.listenTo(EventBus, 'following:toggle', this.onToggle);
		this.listenTo(EventBus, 'following:reset', this.onReset);

		// Clear cookie.
		// Not really needed but sometimes some old instruments are still saved in the cookie.
		this.listenTo(this, 'change:count', this.onReset);
	},

	// Toggle follow/unfollow
	onToggle: function(isin) {
		var isins = this.get('isins');

		if ($.inArray(isin, isins) === -1) {
			isins.push(isin);
		} else {
			isins = _.without(isins, isin);
		}

		this.save({
			'isins': isins,
			'count': isins.length
		});
	},

	onReset: function() {
		if (this.get('count') === 0) {
			var isins = [];

			this.save({
				'isins': isins,
				'count': isins.length
			});
		}
	},

	fetchData: function() {
		var cookie = Cookies.get(this.id);

		if (cookie) {
			this.set(JSON.parse(cookie));
		}
	},

	save: function(attributes) {
		//set new value's to this model
		this.set(attributes);

		// Set cookie
		Cookies.set(this.id, JSON.stringify(this.toJSON()), { expires: 365, path: '/' });
	}
});
