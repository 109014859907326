import _ from 'underscore';
import Backbone from 'backbone';

import EventBus from '../../utils/eventbus';
import ListItemView from './list-item';
import PagingView from './paging';

export default Backbone.View.extend({
	views: {},

	initialize: function() {
		// Cache elements
		this.$list = $('.js-instrument-list');
		this.$listItems = $('.js-instrument');
		this.$paging = $('.js-pagination');

		// If has paging
		if (this.collection.paging && this.$paging.length) {
			this.views.paging = new PagingView({ el: this.$paging, model: this.model, collection: this.collection.paging });
		}

		// Listeners
		this.listenTo(this.collection.instruments, 'reset', this.addAll);
		this.listenTo(EventBus, 'following:remove', this.removeOne);
	},

	render: function() {
		this.attachToView();

		// Render subviews
		_.each(this.views, function(view, key) {
			view.render();
		});

		return this;
	},

	// Bind collection (models) to html views
	// http://lostechies.com/derickbailey/2011/09/26/seo-and-accessibility-with-html5-pushstate-part-2-progressive-enhancement-with-backbone-js/
	attachToView: function() {
		var collection = this.collection.instruments;

		if (!collection.length) {
			return;
		}

		this.$listItems.each(function() {
			var view,
				self = this,
				id = self.getAttribute('data-id'),
				model = collection.get(id);

			if (model) {
				view = new ListItemView({
					el: self,
					model: model
				});
			}
		});
	},

	// Add all items
	addAll: function() {
		var container = document.createDocumentFragment();

		this.collection.instruments.each(function(model) {
			container.appendChild(this.renderOne(model).el);
		}, this);

		this.$list.html(container);
	},

	// Render one item
	renderOne: function(model) {
		return new ListItemView({ model: model }).render();
	},

	// Used in views/following/index.js
	removeOne: function(isin) {
		this.collection.instruments.remove(this.collection.instruments.get(isin));
	}
});
