import Backbone from 'backbone';
import PagingModel from '../models/paging';

export default Backbone.Collection.extend({
	model: PagingModel,

	url: function() {
		return document.URL;
	},

	parse: function(response) {
		return response.Paging;
	}
});
