import Backbone from 'backbone';
import Mustache from 'mustache';

import iconTemplate from '../../templates/icon.html';

export default Backbone.View.extend({
	events: {
		'click .js-pagination-link': 'onPagingClick'
	},

	template: $('#paging-tmpl').html(),

	initialize: function() {
		// Parse template
		Mustache.parse(this.template);

		// Event listeners
		this.listenTo(this.collection, 'reset', this.render);
	},

	render: function() {
		var data = JSON.parse(JSON.stringify(this.collection)),
			html = Mustache.render(this.template, data, {
			'Partials/Icon': iconTemplate
		});

		this.$el.html(html);

		return this;
	},

	// Private
	onPagingClick: function(e) {
		var	page = $(e.currentTarget).data('page'),
			url = $(e.currentTarget).attr('href');

		// Prevent default click behavior
		e.preventDefault();

		// Set page in model
		this.model.set({
			'Page': page,
			'Url': url
		});
	}
});
