import Backbone from 'backbone';

export default Backbone.View.extend({
	events: {
		'click .js-tab-list-trigger': 'toggleCollapse'
	},

	initialize: function() {
		this.el.classList.add('tab-list--collapsed');
	},

	toggleCollapse: function() {
		this.el.classList.toggle('tab-list--collapsed');
	}
});
