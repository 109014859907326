import Backbone from 'backbone';

import EventBus from '../../utils/eventbus';
import Utils from '../../utils/utils';
import FollowingModel from '../../models/following';

export default Backbone.View.extend({
	initialize: function() {
		// Init model
		this.model = new FollowingModel();

		// SetTimeOut timer
		this.timer = 0;

		// Cache elements
		this.$followingAdded = this.el.querySelector('.js-following-added');
		this.$followingRemoved = this.el.querySelector('.js-following-removed');
		this.$iconFollow = this.el.querySelector('.js-icon-follow');
		this.$iconUnfollow = this.el.querySelector('.js-icon-unfollow');
		this.$followingCount = $('.js-following-count');
		this.$followingName = $('.js-following-name');

		// Event Listener
		this.listenTo(EventBus, 'following:change', this.onChange);
		this.listenTo(EventBus, 'following:change', this.changeFavoriteText);

		this.listenTo(this.model, 'change:count', this.onChangeCount);
	},

	render: function() {
		return this;
	},

	onChange: function() {
		var self = this;

		// Activate the bar
		this.el.classList.remove('is-hidden');

		//Hide bar after x sec
		clearTimeout(this.timer);
		this.timer = setTimeout(function() {
			self.el.classList.add('is-hidden');
		}, 3000);
	},

	// Update count number
	onChangeCount: function() {
		Utils.animateTick(this.$followingCount, this.model.get('count'), 0);
	},

	changeFavoriteText: function(name, status) {
		// Update status text
		if (status === 'added') {
			this.$followingAdded.classList.remove('is-hidden');
			this.$followingRemoved.classList.add('is-hidden');

			this.$iconFollow.classList.add('is-hidden');
			this.$iconUnfollow.classList.remove('is-hidden');
		} else {
			this.$followingAdded.classList.add('is-hidden');
			this.$followingRemoved.classList.remove('is-hidden');

			this.$iconFollow.classList.remove('is-hidden');
			this.$iconUnfollow.classList.add('is-hidden');
		}

		// Add instrument name
		this.$followingName.text(name);
	}
});
