import Backbone from 'backbone';

import Globalization from '../../utils/globalization';

export default Backbone.View.extend({
	// Pickadate: http://amsul.ca/pickadate.js/api.htm
	initialize: function() {

		// Get corresponding datepick language
		Globalization.getDatePickerTranslation();

		//Init Picker
		this.$fromPicker = $('#archive-from').pickadate({
			format: 'dd.mm.yyyy',
			max: true
		});

		this.$toPicker = $('#archive-to').pickadate({
			format: 'dd.mm.yyyy',
			max: true
		});

		// Get Picker Object
		this.fromPicker = this.$fromPicker.pickadate('picker');
		this.toPicker = this.$toPicker.pickadate('picker');

		// Add close button
		if (this.fromPicker.$root.find('.picker__button--close').length === 0) {
			this.fromPicker.$root.find('.picker__footer').append('<button class="picker__button--close">Close</button>');
			this.toPicker.$root.find('.picker__footer').append('<button class="picker__button--close">Close</button>');
		}

		// Add close event
		this.fromPicker.$root.find('.picker__button--close').on('click', $.proxy(this.closeDatePicker, this));
		this.toPicker.$root.find('.picker__button--close').on('click', $.proxy(this.closeDatePicker, this));

		// Add set events
		this.fromPicker.on({
			set: $.proxy(this.setPage, this)
		});

		this.toPicker.on({
			set: $.proxy(this.setPage, this)
		});
	},

	render: function() {
		return this;
	},

	// Navigate to the correct archive page
	setPage: function(type) {
		var fromDate = this.fromPicker.get(),
			toDate = this.toPicker.get(),
			archive = Globalization.getNewsArchiveTranslation(),
			url = '';

		// Replace . with -
		fromDate = fromDate.replace(/[^a-z0-9]/gi, '-');
		toDate = toDate.replace(/[^a-z0-9]/gi, '-');
		url = window.location.protocol + '//' + window.location.host + '/' + archive + '/1/' + fromDate + '/' + toDate;

		// If datepicker is closed without picking a date do nothing
		if (type !== null && (fromDate !== '' && toDate !== '')) {
			if (window.location.href !== url) {
				window.location = url;
			}
		}
	},

	// Close datepicker
	closeDatePicker: function() {
		this.fromPicker.close();
		this.toPicker.close();
	}
});
