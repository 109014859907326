import Backbone from 'backbone';
import Mustache from 'mustache';

import EventBus from '../../utils/eventbus';
import FollowNotificationView from '../shared/follow-notification';
import MarketOverviewView from './market-overview';
import MarqueeView from '../shared/marquee';
import NowView from './now';
import IconTpl from '../../templates/Icon.html';

export default Backbone.View.extend({
	initialize: function() {
		// Parse templates
		Mustache.parse(IconTpl);

		// Cache elements
		this.$loader = this.$el.find('.js-loader');

		this.views = {
			followNotification: new FollowNotificationView({ el: '.js-follow-notification' }),
			marquee: new MarqueeView({ el: '.js-marquee'}),
		};

		if (document.querySelector('.js-market-overview')) {
			this.views.marketOverview = new MarketOverviewView({ el: '.js-market-overview' });
			this.views.nowView = new NowView({ el: '.js-now'}),

			this.listenTo(EventBus, 'marketOverview:update', this.onMarketOverviewUpdate);
		}
	},

	render: function() {
		return this;
	},

	onMarketOverviewUpdate: function() {
		var now = new Date();
		var formatter = Intl.DateTimeFormat(document.documentElement.lang, {
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		});

		this.el.querySelector('.js-market-overview-last-update-block').hidden = false;
		this.el.querySelector('.js-market-overview-last-update').textContent = formatter.format(now);
	},

	showLoader: function() {
		this.$loader.addClass('is-loading');
	},

	hideLoader: function() {
		this.$loader.removeClass('is-loading');
	}
});
