import Backbone from 'backbone';
import EventBus from '../../utils/eventbus';

export default Backbone.View.extend({
	events: {
		'click': 'onClick'
	},

	onClick: function() {
		var targetUrl = this.el.href,
			targetUrlWithoutHash = targetUrl.replace(/#.*$/, ''),
			currentUrlWithoutHash = window.location.href.replace(/#.*$/, '');

		if (currentUrlWithoutHash !== targetUrlWithoutHash) {
			return;
		}

		EventBus.trigger('tabTrigger', this.el.getAttribute('data-tab'));
	}
});
