import Backbone from 'backbone';
import _ from 'underscore';

import EventBus from '../../utils/eventbus';
import InstrumentModel from '../../models/instrument';

export default Backbone.View.extend({
	initialize: function() {
		var self = this;

		this.models = this.getIsinList().map(function (isin) {
			return new InstrumentModel({ Isin: isin });
		});

		this.models.forEach(function (model) {
			self.listenTo(model, 'change', self.onModelChange);
		});
	},

	getIsinList: function() {
		var isinList = Array.prototype.slice.call(this.el.querySelectorAll('[data-isin]'))
			.map(function (item) {
				return item.dataset.isin;
			});

		return _.unique(isinList);
	},

	onModelChange: function(instrument) {
		Array.prototype.slice.call(this.el.querySelectorAll('[data-isin="' + instrument.get('Isin') + '"]'))
			.forEach(function (element) {
				var elementPerformance = element.querySelector('.js-performance');
				var elementValue = element.querySelector('.js-value');

				const performance = instrument.get('IntradayChange');
				const value = instrument.get('BidFormatted');

				elementPerformance.textContent = performance ? performance : '-';
				elementValue.textContent = value ? value : '-';
			});

		EventBus.trigger('marketOverview:update');
	}
});
