import Backbone from 'backbone';
import EventBus from '../../utils/eventbus';

function getTabTrigger(container, tabId) {
	return [].slice.call(container.querySelectorAll('.js-tab-item'))
		.filter(function(element) {
			return element.getAttribute('data-tab') === tabId;
		}).pop();
}

export default Backbone.View.extend({
	events: {
		'click .js-tab-item': 'onTabClick'
	},

	initialize: function() {
		this.activeTab = this.el.querySelector('.is-active .js-tab-item');
		this.panels = [].slice.call(this.el.querySelectorAll('.js-tab-panel'));

		EventBus.on('tabTrigger', this.onTabTrigger, this);

		// On slow networks, there are racing conditions between the window
		// load event and when this view is loaded. The lines below makes
		// sure the tab gets initialised. On faster connections this might
		// cause a double trigger, but that shouldn't matter.
		EventBus.on('page:initialize', this.initializeActiveTab, this);
		this.initializeActiveTab();
	},

	initializeActiveTab: function() {
		if (!window.location.hash) {
			return;
		}

		var activeTabId = window.location.hash.substr(1),
			activeTab = getTabTrigger(this.el, activeTabId);

		this.activateTab(activeTab);
	},

	render: function() {
		return this;
	},

	activateTab: function(tab) {
		var clickedTab = tab,
			clickedTabTarget = clickedTab.getAttribute('data-tab');

		EventBus.trigger('tabOpen', clickedTabTarget);
		EventBus.trigger('tabClick:' + clickedTabTarget);

		if (this.activeTab !== clickedTab) {
			this.toggle(clickedTab, clickedTabTarget);
		}
	},

	onTabClick: function(e) {
		e.preventDefault();

		this.activateTab(e.currentTarget);
	},

	onTabTrigger: function(tabId) {
		var tab = getTabTrigger(this.el, tabId);

		if (tab) {
			this.activateTab(tab);
		}
	},

	toggle: function(clickedTab, clickedTabTarget) {
		var targetPanel = this.el.querySelector('#' + clickedTabTarget);

		this.panels.forEach(function(panel) {
			panel.classList.add('is-hidden');
			targetPanel.classList.remove('is-hidden');
		});

		// Toggle active state class for tab
		this.activeTab.parentElement.classList.remove('is-active');
		clickedTab.parentElement.classList.add('is-active');

		// Update active tab
		this.activeTab = clickedTab;
	}
});
