import Backbone from 'backbone';

export default Backbone.View.extend({
	events: {
		'change #language-select': 'setLanguage',
		'click #ga-partner-berne': 'setPartnerBerne',
		'click #ga-partner-vi': 'setPartnerVI',
		'click #ga-instrument-reporting-duty': 'setDownloadReportingDuty'
	},

	initialize: function() {
		if (!window.ga) {
			this.events = {};
			this.delegateEvents();
		}
	},

	// send, event, category, action, label, value
	setLanguage: function(e) {
		var value = $('#language-select').find('option:selected').data('lang');

		window.ga('send', 'event', 'Language', 'Language selecter', value);
	},

	setPartnerBerne: function(e) {
		window.ga('send', 'event', 'Partner', 'Partner: BX Berne eXchange');
	},

	setPartnerVI: function(e) {
		window.ga('send', 'event', 'Partner', 'Partner: VI Company');
	},

	setDownloadReportingDuty: function(e) {
		window.ga('send', 'event', 'Instrument Overview', 'Download', 'Reporting Duty download');
	}
});
