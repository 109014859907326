import Backbone from 'backbone';

export default Backbone.View.extend({
	events: {
		'click .to-following': 'setFollowingToggle',
		'change #sorting': 'setFilterSort',
		'click [name=instrumentType]': 'setFilterInstrumentType',
		'click [name=domestic]': 'setFilterDomestic',
		'click [name=country]': 'setFilterCountry',
		'click [name=sector]': 'setFilterSector',
		'click [name=segmentType]': 'setFilterSegmentType',
		'click [name=status]': 'setFilterStatus',
		'click [name=flagAsListedSecurity]': 'setFilterListed',
		'click #reset': 'setFilterReset',
		'click #ga-instrument-list-download a': 'setDownloadList',
		'click #paging-nav a': 'setPagingClick'
	},

	initialize: function() {
		if (!window.ga) {
			this.events = {};
			this.delegateEvents();
		}
	},

	// send, event, category, action, label, value
	setFollowingToggle: function(e) {
		var isin = $(e.currentTarget).data('isin'),
			inFollowing = $(e.currentTarget).find('.in-following').length;

		if (inFollowing) {
			window.ga('send', 'event', 'Following', 'Remove', 'Removed Instrument: ' + isin);
		} else {
			window.ga('send', 'event', 'Following', 'Add', 'Added Instrument: ' + isin);
		}
	},

	// send, event, category, action, label, value
	setFilterSort: function(e) {
		var value = $(e.currentTarget).val();
		window.ga('send', 'event', 'Instrument Overview', 'Sorting', 'Sorting: ' + value);
	},

	setFilterInstrumentType: function(e) {
		var value = $(e.currentTarget).val();
		window.ga('send', 'event', 'Instrument Overview', 'Filter', 'Filter InstrumentType: ' + value);
	},

	setFilterInstrumentDomestic: function(e) {
		var value = $(e.currentTarget).val();
		window.ga('send', 'event', 'Instrument Overview', 'Filter', 'Filter Domestic: ' + value);
	},

	setFilterCountry: function(e) {
		var value = $(e.currentTarget).val();
		window.ga('send', 'event', 'Instrument Overview', 'Filter', 'Filter Country: ' + value);
	},

	setFilterSector: function(e) {
		var value = $(e.currentTarget).val();
		window.ga('send', 'event', 'Instrument Overview', 'Filter', 'Filter Sector: ' + value);
	},

	setFilterSegmentType: function(e) {
		var value = $(e.currentTarget).val();
		window.ga('send', 'event', 'Instrument Overview', 'Filter', 'Filter Segment: ' + value);
	},

	setFilterStatus: function(e) {
		var value = $(e.currentTarget).val();
		window.ga('send', 'event', 'Instrument Overview', 'Filter', 'Filter Status: ' + value);
	},

	setFilterListed: function(e) {
		var value = $(e.currentTarget).val();
		window.ga('send', 'event', 'Instrument Overview', 'Filter', 'Filter FlagAsListedSecurity: ' + value);
	},

	setFilterReset: function(e) {
		window.ga('send', 'event', 'Instrument Overview', 'Filter', 'Filter Reset');
	},

	setPagingClick: function(e) {
		var value = $(e.currentTarget).text();
		window.ga('send', 'event', 'Instrument Overview', 'Paging', 'Paging: ' + value);
	},

	setDownloadList: function(e) {
		var $this = $(e.currentTarget),
			download = $this.data('name') || $this.text();

		window.ga('send', 'event', 'Instrument Overview', 'Download', download);
	}
});
