﻿// requestAnimationFrame polyfill
// http://my.opera.com/emoller/blog/2011/12/20/requestanimationframe-for-smart-er-animating
(function() {
	var lastTime = 0,
		vendors = ['ms', 'moz', 'webkit', 'o'],
		x;

	for (x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
		window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
		window.cancelRequestAnimationFrame = window[vendors[x] + 'CancelRequestAnimationFrame'];
	}

	if (!window.requestAnimationFrame) {
		window.requestAnimationFrame = function(callback, element) {
			var currTime = new Date().getTime(),
				timeToCall = Math.max(0, 16 - (currTime - lastTime)),
				id = window.setTimeout(function() { callback(currTime + timeToCall); }, timeToCall);
			lastTime = currTime + timeToCall;
			return id;
		};
	}

	if (!window.cancelAnimationFrame) {
		window.cancelAnimationFrame = function(id) {
			clearTimeout(id);
		};
	}
}());

var landscapeMinWidth = 480,
	smallMinWidth = 600,
	mediumMinWidth = 768,
	largeMinWidth = 960,

	Utils = {
		isLandscape: function() {
			return ('matchMedia' in window) ? window.matchMedia('(min-width: ' + landscapeMinWidth + 'px)').matches : document.documentElement.clientWidth >= landscapeMinWidth;
		},

		isSmall: function() {
			return ('matchMedia' in window) ? window.matchMedia('(min-width: ' + smallMinWidth + 'px)').matches : document.documentElement.clientWidth >= smallMinWidth;
		},

		isMedium: function() {
			return ('matchMedia' in window) ? window.matchMedia('(min-width: ' + mediumMinWidth + 'px)').matches : document.documentElement.clientWidth >= mediumMinWidth;
		},

		isLarge: function() {
			return ('matchMedia' in window) ? window.matchMedia('(min-width: ' + largeMinWidth + 'px)').matches : document.documentElement.clientWidth >= mediumMinWidth;
		},

		isDev: function() {
			return window.location.hostname.indexOf('.local') !== -1; // this.config.get('isDev')
		},

		formatNumbers: function(value, decimals, symbol) {
			symbol = symbol || '';

			if (value === '–') {

				return value + symbol; // Just return the dash

			} else {

				var number = parseFloat(value); // Rate to number
				number = number.toFixed(decimals); // Round to decimals wanted

				return this.formatDecimals(number) + symbol;
			}
		},

		// Add ’ for 1’000.000 numbers
		formatDecimals: function(nStr) {
			nStr += '';

			var x = nStr.split('.'),
				x1 = x[0],
				x2 = x.length > 1 ? '.' + x[1] : '',
				rgx = /(\d+)(\d{3})/;

			while (rgx.test(x1)) {
				x1 = x1.replace(rgx, '$1' + '’' + '$2');
			}

			return x1 + x2;
		}

	};

Utils.animateTick = (function() {
	var duration = 300, // milliseconds
		className = 'quote-tick';

	return function($el, value) {
		var onTransitionEnd = function($el) {
			$el.html(value)
			$el.removeClass(className);
		};

		window.requestAnimationFrame(function() {
			var fallbackTimer = setTimeout(function() {
				onTransitionEnd($el);
			}, duration + 250);

			$el.one('transitionend', function(e) {
					clearTimeout(fallbackTimer);
					onTransitionEnd($el);
				})
				.addClass(className);
		});
	};
})();

export default Utils;
