import Backbone from 'backbone';
import Utils from '../../utils/utils';
import FollowingModel from '../../models/following';

export default Backbone.View.extend({
	initialize: function() {
		// Init model
		this.model = new FollowingModel();

		// Event listeners
		this.listenTo(this.model, 'change', this.onChange);
	},

	render: function() {
		return this;
	},

	onChange: function() {
		// Set value and animate tick
		Utils.animateTick(this.$el, this.model.get('count'), 0);
	}
});
