export default function(url) {
	var xhr = new XMLHttpRequest();

	xhr.open('GET', url, true);
	xhr.responseType = 'document';
	xhr.onload = function(e) {
		try {
			var svg = xhr.responseXML.documentElement;

			svg.setAttribute('class', 'is-hidden');

			document.body.insertBefore(svg, document.body.firstElementChild);
		} catch (err) {
			// error loading svg
		}
	};

	xhr.send();
}
