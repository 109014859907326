import _ from 'underscore';
import Backbone from 'backbone';
import EventBus from '../utils/eventbus';

export default Backbone.Model.extend({
	idAttribute: 'SecurityId',

	defaults: {
		ask: [],
		bid: [],
		hasAsks: false,
		hasBids: false,
		isEmpty: true,
		securityId: ''
	},

	hub: 'orderBook',

	initialize: function() {
		this.listenTo(EventBus, 'socket:publish', this.onSocketPublish);
		this.subscribe();
	},

	// Subscribe to the websockets hub
	subscribe: function(id) {
		id = id || this.get('securityId');

		EventBus.trigger('socket:subscribe', this.hub, id);
	},

	// Unsubscribe from the websockets hub
	unsubscribe: function(id) {
		id = id || this.get('securityId');

		EventBus.trigger('socket:unsubscribe', this.hub, id);
	},

	// Private
	// Publish event from socket manager
	onSocketPublish: function(hub, data) {
		// Only get data from this hub
		if (this.hub === hub) {
			data = _.isString(data) ? JSON.parse(data) : data;

			// Only get data for this instrument
			if (data[this.idAttribute] === this.id) {
				this.setTickData(data);
			}
		}
	},

	// Set the received tick data
	setTickData: function(data) {
		this.set({
			hasAsks: data.Ask.length > 0,
			hasBids: data.Bid.length > 0,
			isEmpty: data.Ask.length === 0 && data.Bid.length === 0,

			ask: data.Ask.map(this.getQuoteModelData),
			bid: data.Bid.map(this.getQuoteModelData)
		});
	},

	getQuoteModelData(quote) {
		return {
			count: quote.Count,
			isCollection: quote.Count > 1,
			price: quote.Price,
			volume: quote.Volume,
			volumeBarWidth: quote.VolumeBarWidth,
			volumeBarWidthPercent: (quote.VolumeBarWidth * 100).toString() + '%'
		};
	},
});
