import _ from 'underscore';
import Backbone from 'backbone';
import Highcharts from 'highcharts/highstock';

import EventBus from '../../utils/eventbus';
import ChartModel from '../../models/chart';
import chartSettings from './chart-config';

export default Backbone.View.extend({
	events: {
		'change [name=timeframe]': 'onChangeTimeframe',
		'change [name=type]': 'onChangeType'
	},

	initialize: function() {
		// Cache elements
		this.$chart = $('#chart');
		this.$timeframe = $('[name=timeframe]');
		this.$type = $('[name=type]');
		this.isLoading = true;

		// Set model
		this.model = new ChartModel({ Isin: this.$el.data('id') });

		// Listen to the model
		this.listenTo(this.model, 'change:timeframe change:type', this.getChartData);
		this.listenTo(this.model, 'request', this.showLoading);
		this.listenTo(this.model, 'sync', this.hideLoading);
		this.listenTo(this.model, 'sync', this.updateSeries);

		// Add Bid/Ask ticks to the chart
		// Using function to pass params to avoid duplicate function
		this.listenTo(this.model, 'change:lineAsk', function(e) {
			this.onChangeQuotes('lineAsk');
		}, this);

		this.listenTo(this.model, 'change:lineBid', function(e) {
			this.onChangeQuotes('lineBid');
		}, this);

		this.listenTo(this.model, 'change:lineNav', function(e) {
			this.onChangeQuotes('lineNav');
		}, this);

		this.listenTo(this.model, 'change:lineVolume', function(e) {
			this.onChangeQuotes('lineVolume');
		}, this);

		// Listen for new ticks -> views/instrument-detail/index.js
		this.listenTo(EventBus, 'chart:BidAskTick', this.onNewPoint);

		// Listen for new ticks -> views/instrument-detail/index.js
		this.listenTo(EventBus, 'chart:VolumeTick', this.onNewVolumePoint);
	},

	render: function() {
		this.renderChart();
		return this;
	},

	renderChart: function() {
		// Extra options
		Highcharts.setOptions({
			lang: {
				loading: this.$chart.data('loading'),	// Get/Set translated Loading text
				thousandsSep: '’'						// Set Thousands seperator
			}
		});

		// Init Chart
		this.$chart.highcharts('StockChart', chartSettings);
		this.getChartData();
	},

	showLoading: function() {
		var chart = this.getChart();

		if (chart) {
			this.isLoading = true;
			chart.showLoading('Loading...');
		}
	},

	hideLoading: function() {
		this.getChart().hideLoading();
		this.isLoading = false;
	},

	// Get data
	getChartData: function() {
		return this.model.fetch({
			context: this
		});
	},

	// Get Chart object
	getChart: function() {
		return this.chart || (this.chart = this.$chart.highcharts());
	},

	// Set init Chart Settings
	updateSeries: function() {
		var chart = this.getChart(),
			isIntraday = this.model.get('timeframe') === 'intraday' || this.model.get('timeframe') === 'hours',
			isFund = this.$chart.data('isfund'),
			type = this.model.get('type'),
			groupFalse = { dataGrouping: { enabled: false } },
			quotesExtremesX = null;

		// Clear chart data
		// To avoid the chart doesn't fill out the entire chart
		chart.get('instrument-bid').setData([], false);
		chart.get('instrument-ask').setData([], false);
		chart.get('instrument-mid').setData([], false);
		chart.get('instrument-nav').setData([], false);
		chart.get('instrument-ohlc').setData([], false);
		chart.get('instrument-volume').setData([], false);

		// Hide all series except Volume
		chart.get('instrument-bid').setVisible(false, false);
		chart.get('instrument-ask').setVisible(false, false);
		chart.get('instrument-mid').setVisible(false, false);
		chart.get('instrument-nav').setVisible(false, false);
		chart.get('instrument-ohlc').setVisible(false, false);

		// If Volume is empty don't show the volume labels and title in the chart
		if (this.model.get('lineVolume').length === 0) {
			chart.get('instrument-volume').setVisible(false, false);
			chart.get('y-axis-volumes').update({ labels: { enabled: false }, title: { text: null } }, false);
		} else {
			chart.get('instrument-volume').setVisible(true, false);
			chart.get('y-axis-volumes').update({ labels: { enabled: true }, title: { text: 'Volume' } }, false);
		}

		// Set Volume data and redraw
		chart.get('instrument-volume').update({ data: this.model.get('lineVolume') }, true);

		// Functionality:
		// - Intraday Line graph shows Bid & Ask
		// - Intraday Other graphs shows Mid
		// - Other timeframe always show mid
		if (type === 'line') {

			if (isIntraday) {
				// Adjust ordinal
				chart.get('x-axis-quotes').update({ ordinal: false }, false);

				// Show series
				chart.get('instrument-bid').setVisible(true, false);
				chart.get('instrument-ask').setVisible(true, false);

				// Set grouping and new data and then redraw
				chart.get('instrument-bid').update(groupFalse, false);
				chart.get('instrument-ask').update(groupFalse, false);

				chart.get('instrument-bid').update({ type: type, data: this.model.get('lineBid') }, true);
				chart.get('instrument-ask').update({ type: type, data: this.model.get('lineAsk') }, true);

			} else {
				// Adjust ordinal
				chart.get('x-axis-quotes').update({ ordinal: true }, false);

				// Show serie
				chart.get('instrument-mid').setVisible(true, false);

				// Set grouping and new data and then redraw
				chart.get('instrument-mid').update(groupFalse, false);
				chart.get('instrument-mid').update({ type: type, data: this.model.get('lineMid') }, true);

				if (isFund) {
					// Show serie
					chart.get('instrument-nav').setVisible(true, false);

					// Set grouping and new data and then redraw
					chart.get('instrument-nav').update(groupFalse, false);
					chart.get('instrument-nav').update({ type: type, data: this.model.get('lineNav') }, true);
				}
			}
		} else {
			// Adjust ordinal
			chart.get('x-axis-quotes').update({ ordinal: true }, false);

			// Show serie
			chart.get('instrument-ohlc').setVisible(true, false);

			// Set grouping and new data and then redraw
			chart.get('instrument-ohlc').update(groupFalse, false);
			chart.get('instrument-ohlc').update({ type: type, data: this.model.get('ohlcMid') }, true);
		}

		// Adjust Volume extremes to match the Bid/Ask/Mid extremes
		quotesExtremesX = chart.get('x-axis-quotes').getExtremes();
		chart.get('x-axis-volumes').setExtremes(quotesExtremesX.dataMin, quotesExtremesX.dataMax, true);

		// Set global extreme values
		this.quotesExtremesY = this.chart.get('y-axis-quotes').getExtremes();

		// Set extremes for y-axis
		this.setExtremesY(chart, this.quotesExtremesY);
	},

	// Update chartModel with new timeframe
	onChangeTimeframe: function(e) {
		var timeframe =  $(e.currentTarget).val();

		this.model.set({
			timeframe: timeframe
		});
	},

	// Update chartModel with new type (line, ohlc, candlestick)
	onChangeType: function(e) {
		var type = $(e.currentTarget).val();

		this.model.set({
			type: type
		});
	},

	// Add new Bid/Ask point to the ChartModel
	onNewPoint: function(ask, bid, timestamp) {
		this.model.addBidAskTick(ask, bid, timestamp);
	},

	// Add new Volume point to the ChartModel
	onNewVolumePoint: function(volume, timestamp) {
		this.model.addVolumeTick(volume, timestamp);
	},

	// Private
	// Type: lineAsk or lineBid
	onChangeQuotes: function(type, quotes) {
		var chart = this.getChart(),
			previousQuotes = this.model.previous(type) || [];

		quotes = quotes || this.model.get(type);

		if (!chart && this.isLoading) {
			return;
		}

		// If timeframe = intraday and just 1 tick changed
		if ((this.model.get('timeframe') === 'intraday' || this.model.get('timeframe') === 'hours') && previousQuotes.length !== 0 && (quotes.length - previousQuotes.length) === 1) {
			this.addPoint(type);
		}
	},

	// Add and animate a point
	addPoint: function(type) {
		var chart = this.getChart(),
			askQuote = null,
			bidQuote = null,
			navQuote = null,
			volumeQuote = null,
			quotesExtremesY = null;

		// No chart, so return
		if (!chart) {
			return;
		}

		// Add the quote to the correct serie ask/bid/volume
		if (type === 'lineAsk') {
			// Get last quote
			askQuote = this.model.getLastAskQuote();

			if (askQuote) {
				chart.get('instrument-ask').addPoint(askQuote);
			}

		} else if (type === 'lineBid') {
			// Get last quote
			bidQuote = this.model.getLastBidQuote();

			if (bidQuote) {
				chart.get('instrument-bid').addPoint(bidQuote);
			}
		}

		else if (type === 'lineNav') {
			// Get last quote
			navQuote = this.model.getLastNavQuote();

			if (navQuote) {
				chart.get('instrument-nav').addPoint(navQuote);
			}
		} else {
			// Get last quote
			volumeQuote = this.model.getLastVolumeQuote();

			if (volumeQuote) {
				chart.get('instrument-volume').addPoint(volumeQuote);

				// Show Volume chart if visibility is false
				if (!chart.get('instrument-volume').visible) {
					chart.get('instrument-volume').setVisible(true, false);
					chart.get('y-axis-volumes').update({ labels: { enabled: true }, title: { text: 'Volume' } }, false);
				}
			}
		}

		// Get extremes for y-axis
		quotesExtremesY = chart.get('y-axis-quotes').getExtremes();

		// Compare latest extremes with current extremes and update y-axis if values are different
		if (!_.isEqual(quotesExtremesY, this.quotesExtremesY)) {
			this.setExtremesY(chart, quotesExtremesY);
		}

	},

	setExtremesY: function(chart, quotesExtremesY) {
		// Make sure y-axis is not negative
		var minYAxis = Math.max(quotesExtremesY.dataMin, 0);

		// Set extremes for y-axis
		chart.get('y-axis-quotes').setExtremes(minYAxis, quotesExtremesY.dataMax, true);

		// Cache latest extremes
		this.quotesExtremesY = quotesExtremesY;
	}
});
