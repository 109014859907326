import Backbone from 'backbone';
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

Swiper.use([Autoplay, Navigation, Pagination]);

export default Backbone.View.extend({
	render: function () {
		var swiper = new Swiper(this.el, {
			loop: true,

			autoplay: {
				delay: 15000,
			},

			navigation: {
			  nextEl: '.swiper-button-next',
			  prevEl: '.swiper-button-prev',
			},

			pagination: {
			  el: '.swiper-pagination',
			},

			scrollbar: {
			  el: '.swiper-scrollbar',
			},
		});

		swiper.autoplay.start();
	},
});
