import Backbone from 'backbone';

export default Backbone.View.extend({
	events: {
		'change [name=report-archive]': 'redirect'
	},

	initialize: function() {
		this.activeYear = $('#report-archive-year').val();
		this.activeMonth = $('#report-archive-month').val();
	},

	render: function() {
		return this;
	},

	redirect: function() {
		var year = $('#report-archive-year').val(),
			month = $('#report-archive-month').val();

		if (year !== this.activeYear || month !== this.activeMonth) {
			document.location = '/report-archive/' + year + '/' + month;
		}
	}
});
