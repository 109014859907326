import Backbone from 'backbone';

export default Backbone.View.extend({
	events: {
		'click #broker-dropdown a': 'setBrokerClick',
		'change #chart-select-timeframe': 'setChartTimeframe',
		'change #chart-select-type': 'setChartType',
		'click #ga-instrument-news li a': 'setNewsClick',
		'click #ga-instrument-download-trades': 'setDownloadTrades',
		'click #ga-instrument-download-summary': 'setDownloadDailySummary',
		'click #ga-tab-profile': 'setTabProfileClick',
		'click #ga-tab-downloads': 'setTabDownloadsClick',
		'click .to-following': 'setFollowingToggle'
	},

	initialize: function() {
		if (!window.ga) {
			this.events = {};
			this.delegateEvents();
		}
	},

	// send, event, category, action, label, value
	setBrokerClick: function(e) {
		var value = $(e.currentTarget).text();

		window.ga('send', 'event', 'Instrument Detail', 'Brokers', 'Clicked on Broker: ' + value);
	},

	setChartTimeframe: function(e) {
		var value = $(e.currentTarget).val();

		window.ga('send', 'event', 'Instrument Detail', 'Chart Timeframe', 'Chart Timeframe: ' + value);
	},

	setChartType: function(e) {
		var value = $(e.currentTarget).val();

		window.ga('send', 'event', 'Instrument Detail', 'Chart Type', 'Chart Type: ' + value);
	},

	setNewsClick: function(e) {
		window.ga('send', 'event', 'Instrument Detail', 'News', 'Clicked to Finanzen News item');
	},

	setDownloadTrades: function(e) {
		window.ga('send', 'event', 'Instrument Detail', 'Download', 'Trades download');
	},

	setDownloadDailySummary: function(e) {
		window.ga('send', 'event', 'Instrument Detail', 'Download', 'Daily Summary download');
	},

	setTabProfileClick: function(e) {
		window.ga('send', 'event', 'Instrument Detail', 'Tabs', 'Profile Tab');
	},

	setTabDownloadsClick: function(e) {
		window.ga('send', 'event', 'Instrument Detail', 'Tabs', 'Download Tab');
	},

	// send, event, category, action, label, value
	setFollowingToggle: function(e) {
		var isin = $(e.currentTarget).data('isin'),
			inFollowing = $(e.currentTarget).find('.in-following').length;

		if (inFollowing) {
			window.ga('send', 'event', 'Following', 'Remove', 'Removed Instrument: ' + isin);
		} else {
			window.ga('send', 'event', 'Following', 'Add', 'Added Instrument: ' + isin);
		}
	}
});
