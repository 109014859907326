import _ from 'underscore';
import Backbone from 'backbone';

import EventBus from '../utils/eventbus';
import Globalization from '../utils/globalization';
import InstrumentCollection from '../collections/instruments';
import PagingCollection from '../collections/paging';

export default Backbone.Model.extend({
	defaults: {
		InstrumentCount: null,
		FilteredInstrumentCount: null,
		InstrumentType: '-',
		Countries: '-',
		Sectors: '-',
		SegmentType: '-',
		Status: '-',
		Issuer: '-',
		FlagAsListedSecurity: '-',
		Sorting: 'az',
		Page: 1,
		ItemsPerPage: 25,
		Url: '',
		PagedInstruments: null
	},

	urlRoot: '/instruments',

	url: function() {
		return document.URL;
	},

	initialize: function(attributes, options) {
		// Init nested models & collections
		// Fill it with JSON
		if (this.get('PagedInstruments')) {
			this.instruments = new InstrumentCollection();
			this.instruments.reset(this.get('PagedInstruments').Instruments);

			this.paging = new PagingCollection();
			this.paging.reset(this.get('PagedInstruments').Paging);
		}

		// Listen to filter & sorting changes
		this.listenTo(this, 'change:Countries change:Sorting change:Sectors change:Status change:Issuer change:InstrumentType change:Domestic change:SegmentType change:FlagAsListedSecurity change:ItemsPerPage', this.updateURL);

		// Listen to paging changes
		this.listenTo(this, 'change:Page', _.bind(this.updateURL, this, 'paging'));
	},

	// Update the url with incoming filter & sorting changes
	updateURL: function(type) {
		var sorting = this.get('Sorting'),
			instrumentType = this.get('InstrumentType'),
			domestic = this.get('Domestic'),
			countries = this.get('Countries'),
			sectors = this.get('Sectors'),
			segmentType = this.get('SegmentType'),
			flagAsListedSecurity = this.get('FlagAsListedSecurity'),
			status = this.get('Status'),
			issuer = this.get('Issuer'),
			itemsPerPage = this.get('ItemsPerPage'),
			url = '',
			instrument = Globalization.getInstrumentTranslation();

		// If the event is paging else we are filtering/sorting
		if (type === 'paging') {

			// Set url with the correct page
			url = this.get('Url');

			// Scroll to page title after selecting a page
			$('html, body').stop().animate({
				scrollTop: $('.js-top').offset().top - 40
			}, 400);

		} else {

			// Reset page to 1
			this.set('Page', 1);

			// Set url with page 1 because we used a filter or sorting functionality
			url = '/' + instrument + '/' + sorting + '/' + instrumentType + '/' + domestic + '/' + countries + '/' + sectors + '/' + segmentType + '/' + issuer + '/' + status + '/' + flagAsListedSecurity + '/1' + '/' + itemsPerPage;
		}

		// Set the new model url
		this.url = url;

		// Update the collection with filtered value's
		this.getFilteredCollection();

		// Update navigation url
		EventBus.trigger('navigate', url, { trigger: false, replace: true });
	},

	// Get the filtered instruments & paging
	getFilteredCollection: function() {
		this.fetch({
			cache: false,
			reset: true,
			context: this,
			success: $.proxy(this.resetCollection, this)
		});
	},

	resetCollection: function() {

		// Unsubscribe current instruments in the instrument collection
		this.instruments.unsubscribe();

		// Reset the instrument collection
		this.instruments.reset(this.get('PagedInstruments').Instruments);

		// Reset the paging
		this.paging.reset(this.get('PagedInstruments').Paging);
	}
});
