import Backbone from 'backbone';

export default Backbone.View.extend({
	events: {
		'change': 'onSort'
	},

	render: function() {
		return this;
	},

	onSort: function(e) {
		//var value = e.currentTarget.value;

		//this.model.set('Sorting', value);
	}
});
