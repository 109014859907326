import _ from 'underscore';
import Backbone from 'backbone';

import ListModel from '../../models/list';
import InstrumentFilter from './filters';
import InstrumentSorting from './sorting';
import IndexGAView from './ga';
import InstrumentListView from '../shared/list';
import FollowNotificationView from '../shared/follow-notification';

export default Backbone.View.extend({
	initialize: function() {
		// Init model with data
		this.model = new ListModel(window.ModelData || {});

		// Cache elements
		this.$top = $('.js-top');
		this.$loader = $('.js-loader');

		this.views = {
			filter: new InstrumentFilter({ el: '.js-instrument-filter', model: this.model }),
			sorting: new InstrumentSorting({ el: '.js-instrument-sorting', model: this.model }),
			list: new InstrumentListView({ el: '.js-instruments', model: this.model, collection: this.model }),
			followNotification: new FollowNotificationView({ el: '.js-follow-notification' }),
			googleAnalytics: new IndexGAView({ el: '.js-main' })
		};

		// Listen to the collection
		this.listenTo(this.model, 'request', this.showLoader);
		this.listenTo(this.model, 'sync', this.hideLoader);
		this.listenTo(this.model, 'change', this.updateTitle);
		this.listenTo(this.model, 'change', this.setNoResults);
	},

	render: function() {
		// Render subviews
		_.each(this.views, function(view, key) {
			view.render();
		});

		return this;
	},

	showLoader: function() {
		this.$loader.addClass('is-loading');
	},

	hideLoader: function() {
		this.$loader.removeClass('is-loading');
	},

	// Show/Hide no results row
	setNoResults: function() {
		var filtered = this.model.get('FilteredInstrumentCount');

		if (filtered === '0') {
			$('.js-instrument-list-empty').removeClass('is-hidden');
			$('.js-pagination').addClass('is-hidden');
		} else {
			$('.js-instrument-list-empty').addClass('is-hidden');
			$('.js-pagination').removeClass('is-hidden');
		}
	},

	// Update Page Title
	updateTitle: function() {
		var total = this.model.get('InstrumentCount'),
			filtered = this.model.get('FilteredInstrumentCount'),
			singleTitle = this.$top.data('single-instrument'),
			multipleTitle = this.$top.data('multiple-instruments'),
			preposition = this.$top.data('preposition');

		if (filtered === total) {
			this.$top.text(filtered + ' ' + multipleTitle);
		} else if (filtered === '1') {
			this.$top.text(filtered + ' ' + singleTitle + ' ' + preposition + ' ' + total);
		} else {
			this.$top.text(filtered + ' ' + multipleTitle + ' ' + preposition + ' ' + total);
		}
	}
});
