import Backbone from 'backbone';
import EventBus from '../utils/eventbus';

import FollowingView from '../views/following';
import HomeView from '../views/home';
import InstrumentDetailView from '../views/instrument-detail';
import InstrumentOverviewView from '../views/instrument-overview';
import NewsArchiveView from '../views/news/archive';
import NewsDetailView from '../views/news/detail';
import ReportArchiveView from '../views/report/archive';
import SearchView from '../views/search';
import WhyBxView from '../views/why-bx';

export default Backbone.Router.extend({
	views: {},

	routes: {
		'': 'home',

		'instruments': 'instrumentIndex',
		'instrumente': 'instrumentIndex',
		'titoli': 'instrumentIndex',

		'instruments/:sorting/:instrumentType/:domestic/:country/:sector/:segmentType/:issuer/:status/:flagAsListedSecurity/:page/:itemsPerPage': 'instrumentIndex',
		'instrumente/:sorting/:instrumentType/:domestic/:country/:sector/:segmentType/:issuer/:status/:flagAsListedSecurity/:page/:itemsPerPage': 'instrumentIndex',
		'titoli/:sorting/:instrumentType/:domestic/:country/:sector/:segmentType/:issuer/:status/:flagAsListedSecurity/:page/:itemsPerPage': 'instrumentIndex',

		'instruments/:isin': 'instrumentDetail',
		'instrumente/:isin': 'instrumentDetail',
		'titoli/:isin': 'instrumentDetail',

		'following': 'following',

		'news/archive(/:page)': 'newsArchive',
		'news/archiv(/:page)': 'newsArchive',
		'news/archivio(/:page)': 'newsArchive',

		'news/archive/:page/:datefrom/:dateto': 'newsArchive',
		'news/archiv/:page/:datefrom/:dateto': 'newsArchive',
		'news/archivio/:page/:datefrom/:dateto': 'newsArchive',

		'report-archive(/:year/:month)': 'reportArchive',
		'report-archiv(/:year/:month)': 'reportArchive',
		'report-archivio(/:year/:month)': 'reportArchive',

		'news/(:post)': 'newsDetail',

		'bx-swiss': 'whyBX',

		'search': 'search',
		'chercher': 'search',
		'suchen': 'search',
		'cercare': 'search'
	},

	initialize: function() {
		// Event listeners
		this.listenTo(EventBus, 'navigate', this.navigate);
	},

	home: function() {
		this.views.home = new HomeView({ el: '.js-main' }).render();
		EventBus.trigger('page:initialize');
	},

	// Instrument Overview
	instrumentIndex: function() {
		this.views.instrumentsOverview = new InstrumentOverviewView({ el: '.js-main' }).render();
		EventBus.trigger('page:initialize');
	},

	// Instrument Detail
	instrumentDetail: function() {
		this.views.instrumentsDetail = new InstrumentDetailView({ el: '.js-main' }).render();
		EventBus.trigger('page:initialize');
	},

	// Following page
	following: function() {
		this.views.followingOverview = new FollowingView({ el: '.js-main' }).render();
		EventBus.trigger('page:initialize');
	},

	// Search page
	search: function() {
		this.views.search = new SearchView({ el: '.js-main' }).render();
		EventBus.trigger('page:initialize');
	},

	// News Detail
	newsDetail: function() {
		this.views.newsDetail = new NewsDetailView({ el: '.js-main' }).render();
		EventBus.trigger('page:initialize');
	},

	// News archive
	newsArchive: function() {
		this.views.newsArchive = new NewsArchiveView({ el: '.js-main' }).render();
		EventBus.trigger('page:initialize');
	},

	reportArchive: function() {
		this.views.reportArchive = new ReportArchiveView({ el: '.js-main' }).render();
		EventBus.trigger('page:initialize');

		require(['site/views/report/archive'], _.bind(function(View) {

		}, this));
	},

	// Why BX Swiss page
	whyBX: function() {
		this.views.whyBx = new WhyBxView({ el: '.js-main' }).render();
		EventBus.trigger('page:initialize');
	}
});
