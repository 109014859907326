import _ from 'underscore';

import Backbone from 'backbone';
import Config from '../config';
import EventBus from '../utils/eventbus';

var HubModel = Backbone.Model.extend({
	defaults: {
		HubName: ''
	},

	initialize: function() {
		this.connectHub();
	},

	// Setup the hub connection and callback
	// A model has reference to the collection it part of
	connectHub: function() {
		var hubName = this.get('HubName');

		try {
			// Set hub property
			this.hub = this.collection.connection[hubName];

			// Set data received callback
			this.hub.client.publish = _.bind(this.onHubPublish, this);
		} catch(err) {
			console.error('Hub not available: ', hubName);
		}
	},

	// Private
	// Trigger 'socket:publish' on the event bus with the following params:
	// - hub (e.g. 'product')
	// - data object
	onHubPublish: function(data) {
		EventBus.trigger('socket:publish', this.id, data);
	},

	// Subscribe to hub updates
	subscribe: function(data) {
		if (!(data && this.hub)) {
			return;
		}

		this.hub.server.subscribe(data);
	},

	// Unsubscribe to hub updates
	unsubscribe: function(data) {
		if (!(data && this.hub)) {
			return;
		}

		this.hub.server.unsubscribe(data);
	}
});

export default Backbone.Collection.extend({
	// Hub Model for keeping track of the connected hubs
	model: HubModel,

	// Connection properties
	connection: null,
	connectionReady: false,

	// Queue for caching (un)subscribe actions
	queue: [],

	initialize: function() {

		// Check required dependencies
		if (!$.connection || ($.signalr && $.signalr.dummy)) {
			this.connection = false;

			EventBus.trigger('app:error', '$.connection or hub not available!');

			return;
		}

		// Connect to signalR
		this.connectSocket();

		this.listenTo(EventBus, 'socket:subscribe', this.subscribe);
		this.listenTo(EventBus, 'socket:unsubscribe', this.unsubscribe);
	},

	// Connect to the websockets hub.
	// With hubs you only need 1 connection per App.
	connectSocket: function() {
		var url = Config.get('pushEndPoint'),
			hubs = Config.get('hubs'),
			iOS,
			transport;

		this.connection = $.connection;
		this.connection.hub.logging = false;
		this.connection.hub.url = url; // Enable cross-domain requests (CORS)

		// Add (create) the hubs
		this.addHubs(hubs);

		// Start connection
		//TEMP FIX FOR IOS --> USING LONGPOLLING TO PREVENT CRASH3
		iOS = (navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false);
		transport = iOS ? 'longPolling' : 'auto';

		this.connection.hub.start({ transport: transport })
			.done(_.bind(this.onStart, this))
			.fail(function() {
				EventBus.trigger('app:error', 'Could not start socket connection');
			});
	},

	// Private
	// Set connection ready and process the queue
	onStart: function() {
		// Set connection ready
		this.connectionReady = true;

		// Process queue
		this.processQueue();
	},

	// Hubs and callbacks have to be created before the connction is started!
	// http://www.asp.net/signalr/overview/hubs-api/hubs-api-guide-javascript-client#establishconnection
	addHubs: function(hubs) {
		_.each(hubs, function(value, key) {
			this.add({
				id: key, // internal name
				HubName: value // signalR (server) name
			});
		}, this);
	},

	// Add to the queue when the connection is still being setup
	addToQueue: function(action, hub, ids) {
		this.queue.push([action, hub, ids]);
	},

	// Process the queue after the connection has been made
	processQueue: function() {
		if (this.queue.length) {
			_.each(this.queue, function(item, i) {
				var method = item[0],
					hub = item[1],
					ids = item[2];

				// Call (un)subscribe method
				this[method].call(this, hub, ids);

			}, this);

			// Reset queue
			this.queue = [];
		}
	},

	// Subscribe to the websockets hub
	subscribe: function(hub, ids) {
		this.callHubAction('subscribe', hub, ids);
	},

	// Unsubscribe from the websockets hub
	unsubscribe: function(hub, ids) {
		this.callHubAction('unsubscribe', hub, ids);
	},

	// (Un)subscribe to the hub
	// Queue actions when the connection is not ready yet
	callHubAction: function(action, hub, ids) {
		var connectedHub = this.get(hub),
			isReady = this.connectionReady;

		// Not ready, queue action
		if (!isReady) {
			this.addToQueue(action, hub, ids);
		}
		else if (connectedHub) {
			connectedHub[action](ids);
		}
		else {
			EventBus.trigger('app:error', action + ' for socket hub "' + hub + '" does not exist');
		}
	}
});
