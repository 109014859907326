import _ from 'underscore';
import Backbone from 'backbone';

export default Backbone.Model.extend({
	idAttribute: 'Isin',

	defaults: {
		Isin: null,
		lineAsk: [],
		lineBid: [],
		lineMid: [],
		ohlcMid: [],
		lineVolume: [],
		lineNav: [],
		timeframe: 'hours', //hours, intraday, week, month, year, alltime
		type: 'line' //line, ohlc, candlestick
	},

	url: function() {
		return '/quotedataprovider/' + this.get('type') + '/' + this.get('timeframe') + '/' + this.get('Isin');
	},

	// Get the last value from a Ask/Bid array.
	getLastAskQuote: function() {
		return _.last(this.get('lineAsk'));
	},

	getLastBidQuote: function() {
		return _.last(this.get('lineBid'));
	},

	getLastNavQuote: function() {
		return _.last(this.get('lineNav'));
	},

	getLastVolumeQuote: function() {
		return _.last(this.get('lineVolume'));
	},

	// Add bid/ask tick
	addBidAskTick: function(dataAsk, dataBid, timestamp) {
		// Only add a tick for the 'hours'/'intraday' charts
		if (this.get('timeframe') !== 'hours' && this.get('timeframe') !== 'intraday') {
			return false;
		}

		// Create copies, otherwise you'll just change the reference and then the change event won't get fired
		var askQuote = _.clone(this.get('lineAsk')),
			bidQuote = _.clone(this.get('lineBid'));

		askQuote.push([timestamp, dataAsk]);
		bidQuote.push([timestamp, dataBid]);

		this.set({
			'lineAsk': askQuote,
			'lineBid': bidQuote
		});
	},

	// Add a single tick
	addVolumeTick: function(volume, timestamp) {
		// Only add a tick for the 'hours'/'intraday' charts
		if (this.get('timeframe') !== 'hours' && this.get('timeframe') !== 'intraday') {
			return false;
		}

		// Create copies, otherwise you'll just change the reference and then the change event won't get fired
		var volumeQuote = _.clone(this.get('lineVolume'));

		// JS somehow converts these to strings...
		// *1 converts the value's back to numbers.
		volumeQuote.push([timestamp, volume * 1]);

		this.set({
			'lineVolume': volumeQuote
		});
	}
});
