import _ from 'underscore';
import Backbone from 'backbone';

import DetailDatePickerView from './detail-picker';

export default Backbone.View.extend({
	views: {},

	initialize: function() {

		// Sub views
		this.views = {
			datePicker: new DetailDatePickerView({ el: '#select-news-date' })
		};
	},

	render: function() {

		// Render subviews
		_.each(this.views, function(view, key) {
			view.render();
		});

		return this;
	}
});
