import Backbone from 'backbone';
import moment from 'moment';

export default Backbone.View.extend({
	initialize: function() {
		this.format = this.createFormatter();

		requestAnimationFrame(this.update.bind(this));
	},

	createFormatter: function () {
		const formatOptions = {
			weekday: 'long',
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		};

		const momentFormat = 'dddd, LL';

		if (window.Intl && window.Intl.DateTimeFormat) {
			var formatter = Intl.DateTimeFormat(document.documentElement.lang, formatOptions);

			return function (date) {
				return formatter.format(date);
			};
		}

		return function (date) {
			return moment(date).format(momentFormat);
		};
	},

	update: function() {
		requestAnimationFrame(this.update.bind(this));

		const now = new Date();

		this.el.textContent = this.format(now);
	},
});
