import AppRouter from './routers/app';
import AppView from './views/app';
import Backbone from 'backbone';
import Config from './config';
import Globalization from './utils/globalization';
import iconLoader from '../shared/utils/iconLoader';
import SocketManager from './utils/socketManager';

Globalization.setLanguage(Config.get('locale'));

iconLoader(document.body.getAttribute('data-svg-sprite'));

export default {
	// Keep track of views, models, collections and routers (when needed).
	views: {
		app: new AppView({ el: 'body' }).render()
	},

	// Routers
	routerApp: new AppRouter(),

	// Sockets
	sockets: new SocketManager(),

	// The root path to run the application.
	root: '/',

	// Start the app with Backbone routing
	start: function() {
		Backbone.history.start({
			pushState: true,
			hashChange: false,
			root: this.root
		});
	}
};
