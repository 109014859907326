import _ from 'underscore';
import Backbone from 'backbone';

export default Backbone.View.extend({
	activeClass: 'is-active',

	events: {
		'change': 'onChange',
		'click #reset': 'onReset',
		'click .js-filter-toggle': 'onToggle'
	},

	initialize: function() {
		this.inputs = [].slice.call(this.el.querySelectorAll('.js-filter-input'));
	},

	render: function() {
		return this;
	},

	// Open/Close filter 'tab'
	onToggle: function(e) {
		var clickTarget = e.currentTarget,
			clickTargetIcon = clickTarget.querySelector('.js-filter-icon'),
			toggleTargetId = clickTarget.getAttribute('href'),
			toggleTarget = this.el.querySelector(toggleTargetId);

		clickTargetIcon.classList.toggle('is-flipped');
		toggleTarget.classList.toggle('visually-hidden');

		e.preventDefault();
	},

	// On check add the value of that checked box to the corresponding array
	onChange: function(e) {
		var data = {};

		// Loop through checkboxes and add the values to the corresponding array
		this.inputs.forEach(function(input) {
			if (input.checked) {
				if (data[input.name]) {
					data[input.name].push(input.value);
				} else {
					data[input.name] = [input.value];
				}
			}
		});

		// Trigger the selected filters
		this.reFilterPage(data);
	},

	// Debounce ajax call
	reFilterPage: _.debounce(function(data) {
		// Join arrays to comma seperated strings
		var instrumentTypeArr	= data.instrumentType ? data.instrumentType.join(',') : '-',
			domesticArr		= data.domestic ? data.domestic.join(',') : '-',
			countryArr		= data.country ? data.country.join(',') : '-',
			sectorArr		= data.sector ? data.sector.join(',') : '-',
			segmentTypeArr		= data.segmentType ? data.segmentType.join(',') : '-',
			issuerArr			= data.issuer ? data.issuer.join(',') : '-',
			statusArr			= data.status ? data.status.join(',') : '-',
			listedArr		= data.flagAsListedSecurity ? data.flagAsListedSecurity.join(',') : '-',
			itemsPerPageArr		= data.itemsPerPage ? data.itemsPerPage.join(',') : '25',
			sortingArr			= data.sorting ? data.sorting.join(',') : 'az';

		this.model.set({
			InstrumentType: instrumentTypeArr,
			Domestic: domesticArr,
			Countries: countryArr,
			Sectors: sectorArr,
			SegmentType: segmentTypeArr,
			Issuer: issuerArr,
			Status: statusArr,
			FlagAsListedSecurity: listedArr,
			ItemsPerPage: itemsPerPageArr,
			Sorting: sortingArr
		});

	}, 800),

	onReset: function(e) {
		// Reset all filters to '-'
		this.model.set({
			InstrumentType: '-',
			Domestic: '-',
			Countries: '-',
			Sectors: '-',
			SegmentType: '-',
			Issuer: '-',
			Status: '-',
			FlagAsListedSecurity: '-',
			ItemsPerPage: 25,
			Page: 1,
			Sorting: 'az'
		});

		// Reset the checked state for radio buttons and checkboxes back to "all" state
		this.inputs.forEach(function(input) {
			if (input.value === '-' || input.value === '25' || input.value === 'az') {
				input.checked = true;
			} else {
				input.checked = false;
			}
		});

		this.closeFilters();

		e.preventDefault();
	},

	closeFilters: function() {
		var filterIcons = [].slice.call(this.el.querySelectorAll('.js-filter-icon')),
			filters = [].slice.call(this.el.querySelectorAll('.js-filter'));

		// Close all open filters
		filterIcons.forEach(function(icon) {
			icon.classList.remove('is-flipped');
		});

		filters.forEach(function(filter) {
			filter.classList.add('visually-hidden');
		});
	}
});
