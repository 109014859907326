import _ from 'underscore';
import Backbone from 'backbone';

import BannerView from '../views/shared/banner';
import FollowingView from '../views/shared/following-number';
import GlobalGAView from '../views/shared/ga-global';
import LanguagePickerView from '../views/shared/language-picker';
import NavigationView from '../views/shared/navigation';
import NewsletterView from '../views/shared/newsletter';
import TabLinkView from '../views/shared/tab-link';
import TabListView from '../views/shared/tab-list';
import TabsView from '../views/shared/tabs';

// TODO: Fix tracking, breaks in Firefox

export default Backbone.View.extend({
	initialize: function() {
		// Sub views
		this.views = [
			new LanguagePickerView({ el: '.js-language-picker' }),
			new FollowingView({ el: '.js-following-count' }),
			new NewsletterView({ el: '.js-newsletter' }),
			new GlobalGAView({ el: 'body' }),
			new NavigationView({ el: '.js-navigation' })
		];

		if (document.querySelector('.js-banner')) {
			this.views.push(new BannerView({ el: '.js-banner' }));
		}
		if (document.querySelector('.js-tabs')) {
			this.views.push(new TabsView({ el: '.js-tabs' }));
		}
		if (document.querySelector('.js-tab-list')) {
			this.views.push(new TabListView({ el: '.js-tab-list' }));
		}

		var self = this;

		Array.prototype.slice.call(document.querySelectorAll('.js-tab-link')).forEach(function(el) {
			self.views.push(new TabLinkView({ el: el }));
		});
	},

	render: function() {
		// Render subviews
		_.each(this.views, function(view) {
			view.render();
		});
	}
});
